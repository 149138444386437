import React, { useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import "../Calender/calender.css"

const EventCalendar = () => {
  const [date, setDate] = useState(new Date());

  const handleDateChange = (date) => {
    setDate(date);
  };

  return (
      <Calendar
        value={date}
        // onChange={handleDateChange}
      />
  );
};

export default EventCalendar;