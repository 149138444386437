import { useEffect, useState } from "react";
import { ReactComponent as Upload } from "../../assets/images/svg/Upload.svg";
import React from "react";
import FileSaver from "file-saver";

// Location//
import { useLocation } from "react-router-dom";
import { EXCEL_FILE_BASE64 } from "../../constants";
import LabelOptions from "./LabelOptions";

const UploadCustomer = (props) => {
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const campaignId = pathArray.pop();

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    console.log(campaignId, "--------------", props.file.name);
    if (props.file.name === "" || props.file.name === undefined) {
      setIsShown(false);
    } else {
      setIsShown(true);
    }
  });

  const handleFile = (e) => {
    props.setData((oldArray) => {
      return { ...oldArray, file: e.target.files[0] };
    });
    setIsShown((current) => !current);
  };

  const Next = () => {
    if (props.file) {
      props.setActive(props.next);
    }
  };

  ////////////////////////  DOWNLOAD SAMPLE SHEET //////////////////////////

  const handleDownload = () => {
    // Create worksheet
    let dataBlob = EXCEL_FILE_BASE64;
    let sliceSize = 1024;
    let byteCharacters = atob(dataBlob);
    let bytesLength = byteCharacters.length;
    let sliceCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(sliceCount);
    for (let sliceIndex = 0; sliceIndex < sliceCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    let blob = new Blob(byteArrays, { type: "applocation/vnd.ms-excel" });
    FileSaver.saveAs(new Blob([blob], {}), "CampaignExcelSheet.xlsx");
  };

  return (
    <div className="bg-white">
      <p className="text-sm text-slate-400">Step 5/6</p>
      <div className="w-full flex justify-between">
        <h1 className="text-lg font-bold">Let's Upload Your Customers</h1>
        <button
          className="font-bold text-md rounded-md w-36 h-8 bg-green-700 text-white"
          onClick={handleDownload}
        >
          Download Sheet
        </button>
      </div>
      <p className="text-sm text-slate-400 border-b-2 pb-8">
        Please Upload Customers, so that we can create new campaign
      </p>
      <h1 className="text-md text-slate-400 pt-8">File</h1>
      <div className="">
        <label
          htmlFor="dropzone-file"
          className="flex flex-col items-center justify-center md:w-[40rem] h-[5rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div className="flex items-center justify-center pt-5 pb-6 gap-2">
            <Upload
              className={`w-10 h-10 fill-red group-hover:fill-white`}
              viewBox="0 0 35 35"
              fill="url(#a)"
              cx="8.5"
              cy="8.5"
              r="6"
              fillRule="evenodd"
              fillOpacity=".8"
            />
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            onChange={handleFile}
          />
        </label>
      </div>
      {isShown && (
        <div className="mt-4 bg-slate-200 p-2 rounded-md text-sm md:w-[40rem]">
          {campaignId === "0"
            ? props.file.name
            : props.file.name.split("/")[props.file.name.split("/").length - 1]}
        </div>
      )}

      <div className="pt-8 flex justify-end items-end mr-[26.5rem] mt-8">
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          onClick={() => {
            props.setActive(props.back);
          }}
        >
          Back
        </button>
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          style={{
            backgroundImage:
              "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
            color: "white",
          }}
          onClick={Next}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UploadCustomer;
