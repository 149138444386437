import RecentCalls from "./RecentCalls";
import AgentStatus from "../Agentstatus";
import LiveCalls from "./Livecalls";
import UseAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";

import { useState, useEffect } from "react";

function RightBar(props) {
  const { auth } = useAuth();
  const user_id = auth?.user_details?.id;

  const [active, setActive] = useState("RecentCall");

  const axiosPrivate = UseAxiosPrivate();

  // ------------------  Live Calls Data  -------------------- //
  const [liveCallData, setLiveCallData] = useState([]);

  // -------------------- Fetch Live Calls ------------------- //

  const FetchLiveCalls = async () => {
    let config = {
      headers: { "Content-Type": "application/json" },
    };

    await axiosPrivate
      .get(
        process.env.REACT_APP_API_URL +
          "/contactcentre/live-calls/" +
          user_id +
          "/",
        {},
        config
      )
      .then((response) => {
        setLiveCallData(response.data); // Assuming the response contains the data you need
        setActive("LiveCall");

      })
      .catch((error) => {
        console.error("Error fetching live call data:", error);
      });
  };


  return (
    <div>
      <div className="flex justify-between p-6 space-x-4 bg-back-white rounded-t-lg">
        <div className="flex justify-between w-[25rem] ml-8">
          <button
            className={`text-center ${
              active === "LiveCall" ? "text-[#2ACC6A]" : "text-slate-500"
            } font-bold text-lg`}
            onClick={FetchLiveCalls}
          >
            Live Calls
          </button>
          <button
            className={`text-center ${
              active === "RecentCall" ? "text-[#2ACC6A]" : "text-slate-500"
            } text-slate-500 font-bold text-lg`}
            onClick={() => setActive("RecentCall")}
          >
            Recent Calls
          </button>
          <button
            type="button"
            to={"/admin/campaign/list"}
            className="inline-block px-4 py-1.5 bg-yellow-500 text-white font-karla text-[12px] leading-tight rounded-full shadow-md hover:bg-yellow-600 hover:shadow-lg focus:bg-yellow-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-700 active:shadow-lg transition duration-150 ease-in-out"
          >
            Show More
          </button>
        </div>
      </div>
      <hr className="w-6/5 mx-6 space-x-1 h-[1px]  bg-gray-100 border-0 rounded dark:bg-gray-700 mt-[-11px]" />
      <hr
        className={`w-[8rem] ${
          active === "RecentCall" ? "mx-[11rem]" : "mx-6"
        } bg-gradient-to-r from-[#AFD462] via-[#7ecfa6] to-[#93e5ea] mt-[-3px]   space-x-1 h-1 bg-gray-100 border-0 rounded dark:bg-gray-700`}
      />

      <div className="">
        <div className="rounded-lg bg-back-white h-[48rem]">
          {active === "LiveCall" && <LiveCalls liveCallData={liveCallData} />}
          {active === "RecentCall" && (
            <RecentCalls recentCalls={props.recentCalls} />
          )}
        </div>
        <div className="mt-4 bg-white rounded-lg  h-[43rem]">
          <AgentStatus agentstatus={props.agentstatus} />
        </div>
      </div>
    </div>
  );
}
export default RightBar;
