import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import useAuth from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";
import Pagination from "../../components/Table/pagination";

const CustomerList = (props) => {
  const { auth } = useAuth();

  const Initial_pagination = {
    next: "",
    previous: "",
    count: "",
    total_pages: "",
    current_page: 1,
    page_size: 5,
  };

  const [customerList, setCustomerList] = useState([]);

  const [pagination, setPagination] = useState(Initial_pagination);

  const PaginationControl = (type, number) => {
    if (type == "page") {
      if (number <= pagination.total_pages) {
        fetchCampainCustomers(number);
      } else {
        fetchCampainCustomers(1);
      }
    } else if (type == "last") {
      fetchCampainCustomers(pagination.total_pages);
    } else if (type == "prev") {
      if (pagination.current_page > 1) {
        fetchCampainCustomers(pagination.current_page - 1);
      }
    } else if (type == "next") {
      if (pagination.current_page < pagination.total_pages) {
        fetchCampainCustomers(pagination.current_page + 1);
      }
    } else {
      fetchCampainCustomers(1);
    }
  };

  const fetchCampainCustomers = async (pagenumber) => {
    let postData = {
      user_id: auth?.user_details?.useragent,
      user_type: props.user_type,
      page: pagenumber,
    };

    let config = {
      headers: { "Content-Type": "application/json" },
    };

    await axios
      .get(
        process.env.REACT_APP_API_URL + "/contactcentre/customer-list/",
        {
          params: postData,
        },
        config
      )
      .then((res) => {
        console.log("CAMPAIGN CUSTOMER LIST   : ", res);
        setCustomerList(res.data.results);
        setPagination((prevState) => {
          return {
            ...prevState,
            total_pages: res.data?.total_pages || 0,
            previous: res.data?.previous || null,
            next: res.data?.next || null,
            current_page: res.data?.current_page || 0,
            count: res.data?.count || 0,
            page_size: res.data?.page_size || 5,
          };
        });
      });
  };

  useEffect(() => {
    fetchCampainCustomers(1);
    console.log("PROPS :", props);
  }, []);
  return (
    <>
      <div className="overflow-x-auto p-4 relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-800 dark:text-gray-800">
          <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-500 dark:text-gray-500">
            <tr>
              <th scope="col" className="py-3 px-6">
                Sl-No
              </th>
              <th scope="col" className="py-3 px-6">
                Customer
              </th>
              <th scope="col" className="py-3 px-6">
                Campaign
              </th>
              <th scope="col" className="py-3 px-6">
                Phone No
              </th>
              <th scope="col" className="py-3 px-6">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {customerList.map((customer, index) => (
              <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-800">
                <td className="py-4 px-6">
                  {index +
                    1 +
                    (pagination?.current_page - 1) * pagination?.page_size}
                </td>
                <td className="py-4 px-6">{customer.customer_name}</td>
                <td className="py-4 px-6">{customer.campaign_name}</td>
                <td className="py-4 px-6">{customer.phone_number}</td>
                <td className="py-4 px-6">{customer.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {props.edit_fb && (
        <div className="p-8">
          <Pagination
            pagination_data={pagination}
            PaginationControl={PaginationControl}
          />
        </div>
      )}
    </>
  );
};

export default CustomerList;
