import { createSlice } from "@reduxjs/toolkit";


const InitialState = {
    "campaign_status":"stop",
    "campaign_details":{},
    "campaign_customer":{}
}


export const WebCampaignSlice = createSlice({
    name:'campaign',
    initialState:InitialState,
    reducers:{
        campaignStart:(state) =>{

        },
        campaignStop:(state) =>{

        },
        setCampaignCustomers:(state)=>{

        },

    }
})


export const{campaignStart, campaignStop, setCampaignCustomers} = WebCampaignSlice.actions;

export default WebCampaignSlice.reducer;