import { useEffect, useState } from "react";

import { ReactComponent as Edit } from "../../assets/images/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/images/svg/Delete.svg";

import LabelOptions from "./LabelOptions";
import Swal from "sweetalert2";

// Location//
import { useNavigate, useLocation } from "react-router-dom";

function Labels(props) {
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const campaignId = pathArray.pop();
  const navigate = useNavigate();

  // Show Edit Button //
  const [showEdit, setShowEdit] = useState(false);

  // Showing Popup //
  const [showPopUp, setPopUp] = useState(false);

  // Close PopUp //
  const handleClose = () => setPopUp(false);

  const [labelData, setLabelData] = useState();

  // Delete Labels //
  const LabelHandleDelete = (i) => {
    props.setData((prevState) => ({
      ...prevState,
      labels: prevState.labels.filter((item, index) => index !== i),
    }));
  };
  const QuestionHandleDelete = (i) => {
    props.setData((prevState) => ({
      ...prevState,
      questions: prevState.questions.filter((item, index) => index !== i),
    }));
  };

  // Edit Label //
  const [showMultipleOPtions, setShowMultipleOptions] = useState("");

  const editLabel = (obj, index) => {
    setPopUp(true);
    setLabelData(obj);
    setShowEdit(true);

    if (obj.type === "Multiple Options") {
      setShowMultipleOptions("Multiple Options");
    } else {
      setShowMultipleOptions("Question");
    }
  };

  // UpdateLabelData //
  const EditLabelData = (label, type, question) => {
    labelData.label = label;
    labelData.type = type;
    labelData.options = question;
  };

  // Send All Campaign Data to Backend //
  const complete = () => {
    props.sendCampaignDataToBackend();
  };

  //////////// SHOW TABLES /////////////
  const [questionTable, setQuestionTable] = useState(false);
  const [labelTable, setLabelTable] = useState(false);

  useEffect(() => {
    if (props.questions[0] === undefined) {
      setQuestionTable(false);
    } else {
      setQuestionTable(true);
    }
    if (props.labels[0] === undefined) {
      setLabelTable(false);
    } else {
      setLabelTable(true);
    }
  });

  return (
    <div className="bg-back-white">
      <p className="text-sm text-slate-400">Step 6/6</p>
      <h1 className="text-lg font-bold">Let's Add Your Labels</h1>
      <p className="text-sm text-slate-400 border-b-2 pb-8">
        Please add labels, so that we can create new campaign
      </p>
      <h1 className="text-md text-slate-400 pt-8">Add Labels</h1>
      <div className="">
        <button
          className="w-[49rem] text-gray-800 font-semibold py-2 px-4 border border-gray-600 rounded"
          onClick={() => setPopUp(true)}
        >
          Add Feedback
        </button>
        <LabelOptions
          setData={props.setData}
          visible={showPopUp}
          close={handleClose}
          EditLabelData={EditLabelData}
          editBtn={showEdit}
          setShowEdit={setShowEdit}
          labelData={labelData}
          EditLabel={props.labels}
          EditQuestion={props.questions}
          showMultipleOPtions={showMultipleOPtions}
        />
      </div>

      <div className="pt-8 flex justify-end items-end mr-[17rem]">
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          onClick={() => {
            props.setActive(props.back);
          }}
        >
          Back
        </button>

        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          style={{
            backgroundImage:
              "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
            color: "white",
          }}
          onClick={complete}
        >
          {campaignId === "0" ? <div>Create</div> : <div>Update</div>}
        </button>
        {/* ) : (
          <button
            className="font-bold text-lg rounded-full w-36 h-12"
            style={{
              backgroundImage:
                "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
              color: "white",
            }}
            onClick={complete}
          >
            Update
          </button>
        )} */}
      </div>
      {labelTable && (
        <div className="mt-4 mb-4 overflow-x-auto">
          <table className="w-full">
            <thead className="text-sm text-center text-slate-400">
              <tr>
                <th className="p-3">Sl-No</th>
                <th className="p-3">Labels</th>
                <th className="p-3">Options</th>
                <th className="p-3">Type</th>
                <th className="p-3 flex">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-center">
              {props.labels.map((obj, index) => (
                <tr
                  className="border-b-2 border-solid"
                  data-index={index}
                  key={obj.name}
                >
                  <td className="p-3">{index + 1}</td>
                  <td className="p-3">{obj.name}</td>
                  <td className="p-3 max-w-[100px] truncate">
                    {Array.isArray(obj.options)
                      ? obj.options.join(",")
                      : obj.options}
                  </td>
                  <td className="p-3">
                    {campaignId === "0" ? obj.type : "Multiple Options"}
                  </td>
                  <td className="p-3 flex">
                    <button
                      className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                      onClick={() => editLabel(obj, index)}
                    >
                      <Edit
                        className={`width="26" height="26" fill="blue"`}
                        viewBox="0 0 26 26"
                        fill="url(#a)"
                        cx="8.5"
                        cy="8.5"
                        r="6"
                        fillRule="evenodd"
                        fillOpacity=".8"
                      />
                    </button>
                    <button
                      className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                      onClick={() => LabelHandleDelete(index)}
                    >
                      <Delete
                        className={`width="26" height="26" fill="blue"`}
                        viewBox="0 0 26 26"
                        fill="url(#a)"
                        cx="8.5"
                        cy="8.5"
                        r="6"
                        fillRule="evenodd"
                        fillOpacity=".8"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {questionTable && (
        <div className="mt-4 mb-4 overflow-x-auto">
          <table className="w-full">
            <thead className="text-sm text-center text-slate-400">
              <tr>
                <th className="p-3">Sl-No</th>
                <th className="p-3">Questions</th>
                <th className="p-3">Type</th>
                <th className="p-3 flex">Action</th>
              </tr>
            </thead>
            <tbody className="text-sm text-center">
              {props.questions.map((obj, index) => (
                <tr
                  className="border-b-2 border-solid"
                  data-index={index}
                  key={obj.question_name}
                >
                  <td className="p-3">{index + 1}</td>
                  <td className="p-3">{obj.question_name}</td>
                  <td className="p-3">
                    {campaignId === "0" ? obj.type : "Question"}
                  </td>
                  <td className="p-3 flex">
                    <button
                      className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                      onClick={() => editLabel(obj, index)}
                    >
                      <Edit
                        className={`width="26" height="26" fill="blue"`}
                        viewBox="0 0 26 26"
                        fill="url(#a)"
                        cx="8.5"
                        cy="8.5"
                        r="6"
                        fillRule="evenodd"
                        fillOpacity=".8"
                      />
                    </button>
                    <button
                      className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                      onClick={() => QuestionHandleDelete(index)}
                    >
                      <Delete
                        className={`width="26" height="26" fill="blue"`}
                        viewBox="0 0 26 26"
                        fill="url(#a)"
                        cx="8.5"
                        cy="8.5"
                        r="6"
                        fillRule="evenodd"
                        fillOpacity=".8"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
export default Labels;
