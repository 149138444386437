
export const LOGIN_URL = '/contactcentre/login/'
export const USER_DETAILS = '/contactcentre/reauth/agent/'
export const AGENT_ACTIVITY = '/contactcentre/agent/activity/'
export const CUSTOMER_DETAILS = '/contactcentre/get-customer-detail/'
export const CALL_COUNT = '/contactcentre/call_count/'
export const CAMPAGIN_DATA = '/contactcentre/campaign-list/'
export const FEEDBACK = '/contactcentre/customer-feedback/'
export const CAMP_CONTROLL = '/contactcentre/campaign-controll/'
export const ADMIN_DASH_AGENTACTIVITY ='/contactcentre/agent_status/25/'

