// This hook is to get the access token using the reresh cookie during access token expiry
// or relaoding the site

import axios, { axiosPrivate } from "../utils/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.post(
      "/contactcentre/auth/token/refresh/",
      {},
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        Authorization: `Bearer ${auth?.accessToken} `,

      }
    );

    const getUserresponse = await axios.post(
      "/contactcentre/auth/agent/",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${response.data.access} `,
        },
        withCredentials: true,
      }
    );


    setAuth((prev) => {
      return {
        ...prev,
        accessToken: response.data.access,
        roles: getUserresponse.data.roles,
        user_details: getUserresponse.data.user_details,
      };
    });

    return response.data.access;
  };

  return refresh;
};

export default useRefreshToken;
