import React,{useEffect, useState} from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);







const LineCharts = ({label,answerd,not_answerd }) => {



  const data = {
    labels: label,
    datasets: [
      {
        label: "Answerd",
        data: answerd,
        fill: false,
        backgroundColor: "#6BC698",
        borderColor: "#6BC698",
        lineTension: 0.4
      },
      {
        label: "No Answer",
        data: not_answerd,
        fill: false,
        backgroundColor: "#E85434",
        borderColor: "#E85434",
        lineTension: 0.4,
      }
    ]
  };


const options = {
    plugins: {
        legend: {
        display: true,
        position: 'top',
        align:'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          color:'#9AA7B5',
          padding:30,
        }
    },

    tooltip:{
        backgroundColor:"white",
        titleColor:"black",
        bodyColor:"black",
        borderColor:"black",
        borderWidth:"2"
      },
    },

    responsive: true,
    scales: {
        x: {
          border: {
            display: false
          },
            grid: {
                display: false,
              },
              ticks: {
                color: "#232323",
                font: {
                  size: 15,
                  family:"karla",
                  weight:"600"             
                }
                }
              
          },
          y: {
            
            display: false,
            grid: {
                display: false,
              },
            ticks: {
            }
          }
    },
    maintainAspectRatio: false
  };





  return (
    <Line data={data} options={options}/>
  )
}

export default LineCharts