import { useState, useEffect } from "react";
import UseAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

import { ReactComponent as Delete } from "../../assets/images/svg/Delete.svg";
import Swal from "sweetalert2";

const SelectAgent = (props) => {
  const [agentData, setAgentData] = useState(props.agent);
  const [selectedAgent, setSelectedAgent] = useState(props.agents);
  const [currentAgnet, setCurrentAgent] = useState("");

  const handleDelete = (obj, index) => {
    Swal.fire({
      title: "Are you sure to Delete?",
      text: "All your details about the campaign will be deleted",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        props.agents.splice(index, 1);
        setSelectedAgent((current) => current.filter((item) => item !== obj));
      }
    });
  };

  const sendAgentDataToNewCampaign = () => {
    if (props.agents.length !== 0) {
      props.setActive(props.next);
    }
  };

  const handleChange = (event) => {
    setCurrentAgent(event.target.value);

    console.log("Selected agents -----------", selectedAgent);

    const isFound = selectedAgent.some((element) => {
      if (event.target.value === "") {
        return true;
      }
      if (element === event.target.value) {
        return true;
      } else {
        return false;
      }
    });

    if (isFound) {
      console.log("This Agent Already Added");
    } else {
      setSelectedAgent((prevItems) => [...prevItems, event.target.value]);

      props.setData((preState) => ({
        ...preState,
        agents: [...preState.agents, event.target.value],
      }));
    }
  };

  console.log("AgentData -----> ", agentData);

  return (
    <div>
      <p className="text-sm text-slate-400">Step 2/6</p>
      <h1 className="text-lg font-bold">Let's Select Agent</h1>
      <p className="text-sm text-slate-400 border-b-2 pb-8">
        Please select the agents, so that we can create new campaign
      </p>
      <h1 className="text-md text-slate-400 pt-8">Agents</h1>
      <div className="relative w-full flex space-x-2">
        <select
          className="w-full h-12 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-black"
          value={currentAgnet}
          onChange={handleChange}
        >
          <option value={""}></option>
          {agentData.map((d) => (
            <option key={d.id} value={d.id} name="agent">
              {d.agent_username}
            </option>
          ))}
        </select>
      </div>
      {props.agents.length !== 0 && (
        <div className="relative overflow-x-auto mt-12">
          <table className="w-[45rem] text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Sl-No
                </th>
                <th scope="col" className="px-6 py-3">
                  Agents
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedAgent.map((obj, index) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  key={obj}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">
                    {" "}
                    {agentData == []
                      ? []
                      : agentData.filter((agent) => agent.id == obj)[0]
                          ?.agent_username || "".agent_username}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      className="flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4"
                      onClick={(e) => handleDelete(obj, index)}
                    >
                      <Delete
                        className={`width="26" height="26" fill="blue"`}
                        viewBox="0 0 26 26"
                        fill="url(#a)"
                        cx="8.5"
                        cy="8.5"
                        r="6"
                        fillRule="evenodd"
                        fillOpacity=".8"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="pt-8 flex justify-end items-end">
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          onClick={() => {
            props.setActive(props.back);
          }}
        >
          Back
        </button>
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          style={{
            backgroundImage:
              "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
            color: "white",
          }}
          onClick={sendAgentDataToNewCampaign}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};
export default SelectAgent;
