import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Caller } from "../../assets/images/svg/Group 41662.svg";
import { ReactComponent as CutCall } from "../../assets/images/svg/CutCall.svg";
import { ReactComponent as AnswerCall } from "../../assets/images/svg/AnswerCall.svg";
import useFetch from "../../hooks/useFetch";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Swal from "sweetalert2";
import { TopDisappearingMessage } from "../../utils/Common";
import useSocket from "../../hooks/useWebsocket";
import { AGENT_ACTIVITY } from "../../utils/ApiUrls";
import useAuth from "../../hooks/useAuth";
import song from "../../assets/music/ringtone.mp3";
import CallFeedback from "../Feedbacks/Feedback";
import Popup from "reactjs-popup";
import { FEEDBACK } from "../../utils/ApiUrls";
import { act } from "react";

const Call = (props) => {
  const { auth } = useAuth();
  const [fback, setfback] = useState(false);

  // ================  To Show Customer Number in the call Popup ================= //
  const [showCustomerNo, setCustomerNo] = useState(false);

  const initial_fbData = {
    id: "0",
    uid: props.call_id,
    agent_id: "",
    customer_id: "",
    answers: {},
    fbanswer: {},
    comment: "",
    folloup: new Date(),
  };

  const [fbdata, setfbdata] = useState(initial_fbData);
  const [isReady, val, send] = useSocket();
  const [isxReady, setIsReady] = useState(false);
  const [audio, setAudio] = useState(new Audio(song));
  const [isPlaying, setIsPlaying] = useState(false);
  const [incoming, setIncoming] = useState({});
  const [nextCallTime, setNextCallTime] = useState();
  const [feedbacksubmitted, setFeedbackSubmitted] = useState(false);

  const outgoingInitial = {
    camp_details: {},
    channel_unavailable: false,
  };
  const Initialcallstate = {
    showcall: false,
    customer: {},
    call_id: "",
    calltype: "",
    callstatus: "initialized",
  };

  const [outgoing, setOutgoing] = useState(outgoingInitial);
  const [call, setCall] = useState(Initialcallstate);
  const [timer, setTimer] = useState("00:00");
  const [wssocket, setWssocket] = useState();
  const ws = useRef(null);
  const fetchData = useFetch();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
  });

  useEffect(() => {
    if (["unavailable", "break"].includes(props.onlineStatus)) {
      console.log("Agent Status is Unavailable");
      setOutgoing((prev) => {
        return { ...prev, camp_details: {}, channel_unavailable: false };
      });

      setCall(Initialcallstate);

      TopDisappearingMessage(
        "top-start",
        2000,
        "warning",
        "You are not available to make calls",
        false
      );
    }

    (async () => {
      if (props.onlineStatus === "initial_status") {
        return;
      }
      console.log("ONLINE STATUS    :: ", props.onlineStatus);
      const res_data = await fetchData(
        "post",
        { status: props.onlineStatus, usertype: "agent" },
        AGENT_ACTIVITY
      );
      if (res_data?.data === "success") {
        localStorage.setItem("onlineStatus", props.onlineStatus);
        TopDisappearingMessage(
          "top-start",
          2000,
          "warning",
          res_data?.alert_data,
          false
        );
      }
    })();
  }, [props.onlineStatus]);

  const [manualCallData, setManualCallData] = useState();

  ////------------------  Re Call --------------------/////
  const [recall, setRecall] = useState(props.callType);
  useEffect(() => {
    if (props.callType === "recall") {
      setCall((prev) => {
        return {
          ...prev,
          callstatus: "Outgoing",
          showcall: true,
          customer: props.customerDetails,
        };
      });

      audio.play();
    }
  }, [props.customerDetails, recall]);

  /////----------------------- End ------------------------////

  const [campId, setCampId] = useState("");
  useEffect(() => {
    const data = val?.data;
    const action = val?.action;

    console.log("DATA ------------ : ", data);

    console.log("ACTION ---------- : ", action);

    console.log("AGENT STATUS --------- : ", props.onlineStatus);

    const requestCallCustomer = () => {
      console.log(data, "Outgoing When requestCallCustomer     :   ", outgoing);

      console.log("CAMPAIGN ID ---------> : ", campId);

      let camp_id = outgoing?.camp_details?.id;

      console.log(
        "ONLINE STATUS WHEN REQUESTCALLCUSTOMER  : ",
        props.onlineStatus
      );

      if (camp_id === undefined) {
        camp_id = localStorage.getItem("camp_id");
        setCampId(camp_id);
      }

      if (props.onlineStatus === "available") {
        console.log("CAMPAIGN ID ----------- :", camp_id);

        if (camp_id) {
          let camprunn_data = {
            campaign_id: camp_id,
            agent_id: auth?.user_details?.useragent,
            type: "start",
          };
          console.log("NEXT CALL CUSTOMER DATA :", camprunn_data);
          console.log("ACTION 2 -------> : ", action);
          if (action === "call_status") {
            send(
              JSON.stringify({
                data: camprunn_data,
                type: "call_customer",
              })
            );
          } else {
            console.log("call status is : ", action);
          }
        } else {
          TopDisappearingMessage(
            "top-start",
            5000,
            "warning",
            "Campaign Details Removed please do login again"
          );
        }
      }
    };

    if (action === "new_customer") {
    }

    if (action === "campaign_start") {
      let campaign_data = data?.camp_details;

      setCampId(campaign_data.id);

      localStorage.setItem("camp_id", campaign_data.id);

      console.log("DATA WHEN CAMPAIGN START    : ", campaign_data);

      setOutgoing((prev) => {
        return { ...prev, camp_details: campaign_data };
      });

      console.log(
        "OUTGOING WHEN CAMPAIGN START AND UPDATE setOutgoing  --------- : ",
        outgoing
      );

      let timerInterval;
      let closeInSeconds = parseInt(
        data?.camp_details?.call_intervel_time || 5
      );
      let camp_name = data?.camp_details?.name;
      let camp_id = data?.camp_details?.id;

      Swal.fire({
        title: "<h2 style='color:#6BC698'>" + camp_name + "</h5>",
        html: "Campaign Will Start in <strong></strong> seconds",
        timer: closeInSeconds * 1000,
        timerProgressBar: true,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
          let text_con = Swal.getHtmlContainer().querySelector("strong");
          if (text_con) {
            timerInterval = setInterval(() => {
              text_con.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
            }, 100);
          }
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          if (data?.camp_details?.campaign_mode === "auto") {
            let camp_runn_data = {
              campaign_id: camp_id,
              agent_id: auth?.user_details?.useragent,
              type: "start",
            };
            send(
              JSON.stringify({
                data: camp_runn_data,
                type: "call_customer",
              })
            );
          } else {
            let camp_runn_data = {
              campaign_id: camp_id,
              agent_id: auth?.user_details?.useragent,
              type: "start",
            };

            send(
              JSON.stringify(
                {
                  data: camp_runn_data,
                  type: "call_customer",
                },
                setManualCallData(camp_runn_data)
              )
            );
          }
        }
      });
    }

    if (action === "campaign_hold") {
      // if("===agent type is both update poup for  incoming")
      // else:
      // show pop up on campaign is stoped
      // reset all campaign related data
      console.log("=======campaign is holded for some time");
    }

    if (action === "campaign_stop") {
      // if alredy call is going after that we need to reset all state

      setOutgoing((prev) => {
        return { ...prev, camp_details: {} };
      });

      Swal.fire({
        title: "",
        text: data.message,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "CHECK OTHER CAMPAIGN RUNNING",
      }).then((result) => {
        if (result.isConfirmed) {
          let user_id = auth?.user_details?.useragent;
          send(
            JSON.stringify({
              data: { user: user_id },
              type: "chcek_any_camp_running",
            })
          );
        }
      });
    }

    if (action === "campaign_complete") {
      setOutgoing((prev) => {
        return { ...prev, camp_details: {} };
      });

      Toast.fire({
        icon: "success",
        title: "Campaign is Completed",
        timer: 5000, // 5000 milliseconds = 5 seconds
        timerProgressBar: true,
      });
    }

    if (action === "incoming_customer") {
      console.log("=====campaign STOP campaign DATA==", data);
      console.log("=======show incoming call popup====");
    }

    if (action === "no_channel") {
      let type = data?.type;

      if (type === "no_channel") {
        setOutgoing((prev) => {
          return { ...prev, channel_unavailable: true };
        });

        if (outgoing.channel_unavailable) {
          console.log("Requesting Another Call");

          Toast.fire({
            icon: "warning",
            title: "Channel Busy",
          });

          requestCallCustomer();
        } else {
          Swal.fire({
            title: "",
            text: data.message,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Retry",
          }).then((result) => {
            if (result.isConfirmed) {
              requestCallCustomer();
            }
          });
        }
      } else {
        Swal.fire({
          title: "",
          text: data.message,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Retry",
        }).then((result) => {
          if (result.isConfirmed) {
            requestCallCustomer();
          }
        });
      }
    }

    if (action === "call_error") {
    }

    // ----------------------  PROGRESSIVE DIALER WHEN AGENT CHANGE STATUS -------------------- //

    if (action === "progressive_dialer_agent_available") {
      let campaign_data = data?.camp_details;

      setCampId(campaign_data.id);
      setOutgoing((prev) => {
        return { ...prev, camp_details: campaign_data };
      });

      let camp_id = data?.camp_details?.id;

      /* Read more about handling dismissals below */
      if (data?.camp_details?.campaign_mode === "auto") {
        let camp_runn_data = {
          campaign_id: camp_id,
          agent_id: "",
          type: "start",
        };
        send(
          JSON.stringify({
            data: camp_runn_data,
            type: "progressive_dialer_start",
          })
        );
      }
    }

    // -----------------------  END  ---------------------- //

    // ----------------------  PROGRESSIVE DIALER ------------------------//

    if (action === "progressive_dialer_start") {
      if (data?.camp_details?.campaign_mode === "auto") {
        setfback(!fback);
      }
      console.log("PROGRESSIVE ACTION     :   ", action);
      console.log("PROGRESSIVE DATA    :  ", data.customer_details.campaign);

      localStorage.setItem("camp_id", data.customer_details.campaign);

      setfbdata(initial_fbData);
      setCall((prev) => {
        return {
          ...prev,
          callstatus: data.callstatus,
          call_id: data.uid,
          showcall: true,
          calltype: "Outgoing",
          customer: data.customer_details,
        };
      });

      setfbdata((prevState) => {
        return { ...prevState, uid: data.uid };
      });

      // setOutgoing((prev) => {
      //   return { ...prev, channel_unavailable: false };
      // });

      audio.play();
    }
    // --------------------------  PROGRESSIVE CALL END ------------------------ //

    // -------------------------- CALL END AND HIDE CURRENT POPUP AND NEXT CUSTOMER IN PROGRESSIVE DIALER ---------------------//

    if (action === "progressive_call_status") {
      console.log("DATA CALL STATUS ------------- ", data.callstatus);
      if (data.callstatus === "answerd") {
        audio.pause();
        setCall((prev) => {
          return { ...prev, callstatus: data?.status };
        });
      } else if (data.callstatus === "end") {
        audio.pause();

        setCall((prev) => {
          return { ...prev, callstatus: data?.status };
        });

        setTimeout(function () {
          setCall((prev) => {
            return {
              ...prev,
              showcall: false,
              calltype: "Outgoing",
              customer: {},
            };
          });

          if (props.onlineStatus === "available") {
            console.log("AVAILABLE     3");
            let camp_id = outgoing?.camp_details?.id;
            if (camp_id == undefined) {
              camp_id = localStorage.getItem("camp_id");
            }
            console.log("Outgoing in  progressive dialer   :  ");
            console.log("campId : ", campId);
            if (camp_id) {
              let camprunn_data = {
                campaign_id: camp_id,
                agent_id: auth?.user_details?.useragent,
                type: "start",
              };
              send(
                JSON.stringify({
                  data: camprunn_data,
                  type: "progressive_dialer_start",
                })
              );
            }

            // setTimeout(function () {
            //   requestCallCustomer();
            // }, 11000);
          }
        }, 3000);
      } else {
      }
    }

    // --------------------------- PROGRESSIVE   CALL COMPLETED  ------------------------ //

    if (action === "progressive_call_completed") {
      if (data.callstatus === "answerd") {
        console.log("========function to show the connected time");

        audio.pause();

        setCall((prev) => {
          return { ...prev, callstatus: data?.status };
        });
      } else if (data.callstatus === "end") {
        audio.pause();

        setCall((prev) => {
          return { ...prev, callstatus: data?.status };
        });

        setTimeout(function () {
          setCall((prev) => {
            return {
              ...prev,

              showcall: false,

              calltype: "Outgoing",

              customer: {},
            };
          });
        }, 3000);
      }

      setCall((prev) => {
        return {
          ...prev,
          showcall: false,
          calltype: "Outgoing",
          customer: {},
        };
      });
      audio.pause();
    }

    // ----------------------------------------  END  ------------------------------------- //

    if (action === "call_customer") {
      if (outgoing?.camp_details?.campaign_mode === "auto") {
        setfback(!fback);
      }
      setfbdata(initial_fbData);

      setCustomerNo(data.customerNumPermission);
      setCall((prev) => {
        return {
          ...prev,
          callstatus: data.callstatus,
          call_id: data.uid,
          showcall: true,
          calltype: "Outgoing",
          customer: data.customer_details,
        };
      });

      setfbdata((prevState) => {
        return { ...prevState, uid: data.uid };
      });

      setOutgoing((prev) => {
        return { ...prev, channel_unavailable: false };
      });

      audio
        .play()
        .catch((error) => console.error("Failed to play audio:", error));
    }

    if (action === "call_manual_customer") {
      console.log(
        data.customer_details,
        "===================CALL MANUAL CALLING====================",
        data
      );

      setCustomerNo(data.customerNumPermission);

      setCall((prev) => {
        return {
          ...prev,
          callstatus: data.callstatus,
          call_id: data.uid,
          showcall: true,
          calltype: "Outgoing",
          customer: data.customer_details,
        };
      });
    }

    if (action === "call_status") {
      console.log("DATA CALL STATUS ------------- ", data.callstatus);
      if (data.callstatus === "answerd") {
        console.log("========function to show the connected time");
        audio.pause();
        setCall((prev) => {
          return { ...prev, callstatus: data?.status };
        });
      } else if (data.callstatus === "end") {
        audio.pause();

        // Next Call in manual call ...

        if (outgoing.camp_details.campaign_mode == "manual") {
          console.log("feedback submitted ----> : ", feedbacksubmitted);
          if (feedbacksubmitted) {
            console.log("submitted !!!!!!!!!");
            setCall((prev) => {
              return { ...prev, callstatus: data?.status };
            });

            setTimeout(function () {
              setCall((prev) => {
                return {
                  ...prev,
                  showcall: false,
                  calltype: "Outgoing",
                  customer: {},
                };
              });

              if (props.onlineStatus === "available") {
                console.log("AVAILABLE     3");
                let camp_id = outgoing?.camp_details?.id;

                let closeInSeconds = data.closeInSeconds * 1000;
                let timerInterval = data.closeInSeconds;

                TopDisappearingMessage(
                  "top-start",
                  closeInSeconds,
                  "warning",
                  "Next customer in " + timerInterval + " seconds"
                );

                setTimeout(function () {
                  requestCallCustomer();
                }, closeInSeconds);
              }
            }, 3000);
          }
        }

        // Next call generate in auto call

        if (outgoing.camp_details.campaign_mode == "auto") {
          const closeInSeconds = data.closeInSeconds * 1000;
          const timerInterval = data.closeInSeconds;

          TopDisappearingMessage(
            "top-start",
            closeInSeconds,
            "warning",
            "Next customer in " + timerInterval + " seconds"
          );
          console.log(
            typeof data.closeInSeconds,
            "Next Call Time ---->  : ",
            data.closeInSeconds
          );
          setTimeout(function () {
            setCall((prev) => {
              return { ...prev, callstatus: data?.status };
            });

            // setTimeout(function () {
            setCall((prev) => {
              return {
                ...prev,
                showcall: false,
                calltype: "Outgoing",
                customer: {},
              };
            });

            if (props.onlineStatus === "available") {
              console.log("AVAILABLE     3");
              let camp_id = outgoing?.camp_details?.id;

              // let closeInSeconds = data.closeInSeconds * 1000;
              // let timerInterval = data.closeInSeconds;
              // setNextCallTime(closeInSeconds);

              // TopDisappearingMessage(
              //   "top-start",
              //   closeInSeconds,
              //   "warning",
              //   "Next customer in " + timerInterval + " seconds"
              // );

              // setTimeout(function () {
              requestCallCustomer();
              // }, closeInSeconds);
            }
            // }, 3000);
          }, data.closeInSeconds * 1000);
        }
      }
    }
  }, [val, props.onlineStatus, feedbacksubmitted]);

  const callCustomer = () => {
    console.log("manualCallData", manualCallData);
    send(
      JSON.stringify({
        data: manualCallData,
        type: "call_customer",
        call_mode: "manualcall",
      })
    );

    setOutgoing((prev) => {
      return { ...prev, channel_unavailable: false };
    });
    audio.play();
  };

  return (
    <div
      className={`relative ${call?.showcall ? "" : "hidden"} w-full sm:w-20`}
    >
      <Popup
        open={fback}
        modal
        nested
        position="center center"
        contentStyle={{ width: "0px", marginTop: "-10px" }}
      >
        {(close) => (
          <CallFeedback
            setfback={setfback}
            close={close}
            customer={call.customer}
            call_id={call.call_id}
            camp_data={outgoing.camp_details}
            FBdata={fbdata}
            showCustomerNo={showCustomerNo}
            setFBdata={setfbdata}
            setFeedbackSubmitted={setFeedbackSubmitted}
          />
        )}
      </Popup>

      <div
        onClick={() => {
          setfback(!fback);
        }}
        className="fixed lg:top-[31rem] w-[22rem] lg:left-[65rem] right-0 bg-white rounded-lg border-spacing-6 shadow-2xl dark:bg-gray-800"
      >
        <div className={`bg-back-white rounded-md `}>
          <div className="flex space-x-8 p-6">
            <div className="flex justify-center items-center w-[6rem] h-[6rem] mt-3">
              <Caller />
            </div>
            <div className=" text-center text-md font-bold">
              {call?.customer?.customer_name
                ? call.customer.customer_name
                : call.customer.customer_phone_number}
              <br></br>
              {showCustomerNo ? "" : call.customer.customer_phone_number}

              <div className="mt-3">
                <div className="text-sm text-gray-400 flex justify-center text-center">
                  {call?.calltype}
                </div>
                <div className="text-gray-400 flex justify-center text-center">
                  {timer}
                </div>
                <div className="text-sm text-gray-400 flex justify-center text-center">
                  {call?.callstatus}
                </div>
              </div>
            </div>
            <div className="flex justify-center items-end space-x-36">
              {outgoing?.camp_details?.campaign_mode === "manual" ? (
                <AnswerCall
                  className="w-14 h-14 mb-9"
                  onClick={() => callCustomer()}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Call);
