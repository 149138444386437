import React, { useState, useEffect } from "react";
import { ADMIN_DASH_AGENTACTIVITY } from "../../utils/ApiUrls";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import axios from "axios";
import { AGENT_STATUS_COLOR_CODE } from "../../utils/Data";

const Agentstatus = (props) => {
  const agentstatus = props.agentstatus;

  //   const [agentstatus, setAgentStatus] = useState({})

  //   const {auth} = useAuth();
  //   const BASE_URL = process.env.REACT_APP_API_URL
  //   let user_id = auth?.user_details?.user
  //   useEffect(() => {
  //     axios.get(BASE_URL+"/contactcentre/agent_status/"+user_id+"/").then((response) => {
  //       setAgentStatus(response.data);
  //     });

  // }, [])

  return (
    <div className="font-karla">
      <div className="p-6 ">
        <div className="flex justify-between">
          <h1 className="font-bold text-[20px]">Agent Status</h1>
          <Link to="/admin/report/agent">
            <button
              type="button"
              className="inline-block px-4 py-1.5 bg-yellow-500 text-white font-karla text-[12px] leading-tight rounded-full shadow-md hover:bg-yellow-600 hover:shadow-lg focus:bg-yellow-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Show More
            </button>
          </Link>
        </div>
        <div className="flex justify-between items-center pt-4 space-x-4">
          <div className="bg-light-white rounded-md w-full h-16 pt-2">
            <h1 className="text-center font-bold text-lg text-gray-700">
              {agentstatus?.status_count?.available_agents || 0}
            </h1>
            <p className="text-center text-sm text-gray-700">Available</p>
          </div>
          <div className="bg-light-white rounded-md w-full h-16 pt-2">
            <h1 className="text-center font-bold text-lg text-gray-700">
              {agentstatus?.status_count?.not_ready_agents || 0}
            </h1>
            <p className="text-center text-sm text-gray-700">Not Ready</p>
          </div>
        </div>
        <div className="flex justify-between items-center pt-4 space-x-4">
          <div className="bg-light-white rounded-md w-full h-16 pt-2">
            <h1 className="text-center font-bold text-lg text-gray-700	">
              {agentstatus?.status_count?.break_agents || 0}
            </h1>
            <p className="text-center text-sm text-gray-700	">Break</p>
          </div>
          <div className="bg-light-white rounded-md w-full h-16 pt-2">
            <h1 className="text-center font-bold text-lg text-gray-700	">
              {agentstatus?.status_count?.idle_agents || 0}
            </h1>
            <p className="text-center text-sm text-gray-700	">Idle</p>
          </div>
          <div className="bg-light-white rounded-md w-full h-16 pt-2">
            <h1 className="text-center font-bold text-lg text-gray-700	">
              {agentstatus?.status_count?.incall_agents || 0}
            </h1>
            <p className="text-center text-sm text-gray-700	">In Call</p>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto no-scrollbar p-4">
        <table className="w-full table-fixed ">
          <thead>
            <tr className="text-[#9AA7B5] text-[17px] font-karla font-[500]">
              <th className="w-[100px] font-[500] px-4 text-start">Agent</th>
              <th className="w-[200px] font-[500] px-4 text-start">
                Last Login/Log Out
              </th>
              <th className="w-full font-[500] px-4 text-start">Status</th>
            </tr>
          </thead>
          <tbody>
            {agentstatus.agent_details &&
              agentstatus.agent_details.map &&
              agentstatus.agent_details.map((obj, index) => (
                <tr
                  className="text-[16px] font-karla text-gray-900  whitespace-nowrap"
                  key={0 + index}
                >
                  <td className="px-4 py-4 text-center w-fit">
                    {obj.agent_name}
                  </td>

                  <td className="px-4  py-4 text-center">
                    <div>
                      {obj.formatted_time}
                      <br />
                      {obj.formatted_date}
                    </div>
                  </td>

                  <td
                    className={`px-4  py-4 text-center  text-${
                      AGENT_STATUS_COLOR_CODE[obj.activity]
                    }-600`}
                  >
                    <span
                      className={`inline-flex items-center gap-1 rounded-full bg-${
                        AGENT_STATUS_COLOR_CODE[obj.activity]
                      }-50 px-2 py-1 text-xs font-semibold text-${
                        AGENT_STATUS_COLOR_CODE[obj.activity]
                      }-600`}
                    >
                      <span
                        className={`h-1.5 w-1.5 rounded-full bg-${
                          AGENT_STATUS_COLOR_CODE[obj.activity]
                        }-600`}
                      ></span>
                      {obj.activity}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Agentstatus;
