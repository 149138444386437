import { createContext, useEffect,useRef, useState, useMemo } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import useAuth from "../hooks/useAuth";
import {AGENT_ACTIVITY} from "../utils/ApiUrls";
import useFetch from "../hooks/useFetch";
import Swal from 'sweetalert2';
const ActivityContext = createContext({});



const Toast = Swal.mixin({
  toast: true,
  position: 'top-start',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})




export const ActivityProvider = ({children}) =>{

  // Hooks=====================
  const {auth} = useAuth();
  const fetchData = useFetch();

  // Ref to avoid Initial UseeffectFunctions=============
  const isAgentStatusFirstrun = useRef(true)

  
  // Initila state Declarations==================
  const initialactivityState = {
    agentonlineStatus: localStorage.getItem("onlineStatus") || 'initial_status',
  }

  const [activity, setActivity] = useState(initialactivityState);
  const [socket, setSocket ] = useState()
  const [campaigndata, setCampaigndata] = useState({})


// ================================FUNCTIONS=======================================

useEffect(() => {
    let admin_id = auth?.user_details?.user
    let user_id = auth?.user_details?.useragent

    const newSocket = new W3CWebSocket(process.env.REACT_APP_WS_URL+'ws/campaign/'+user_id+'/'+admin_id+'/'); //gets room_name from the state and connects to the backend server
    setSocket(newSocket);
    // console.log("=========SOCKET NEW CONNECTION IS SET==========")
    // return () => newSocket.close();

    // Connection opened
    newSocket.addEventListener('open', function (event) {
      console.log('ws connected', event);
    });

    newSocket.addEventListener('close', function (event) {
      console.log('ws is disconnected');
    });

    newSocket.addEventListener('accept', function (event) {
      console.log('camp_running',event);
    });

    newSocket.addEventListener('message', function (message) {
      const dataFromServer = JSON.parse(message.data);
      let data = dataFromServer.data
      let type = data.type

      console.log("=====data=======", data.camp_details)
      console.log("=====type=======",type)

      // check if agent status is outgoing and both

      if(type == "campaign_start"){
        console.log("AGENT STATUS PROVIDER")
          setCampaigndata(prev => {
            return {...prev,camp_details:data.camp_details}
          })

          let timerInterval
          let closeInSeconds = parseInt(data?.camp_details?.call_intervel_time||0)
          let camp_name = data?.camp_details?.name
          let camp_id = data?.camp_details?.id
          console.log("Campaign Will Start in     22222")
          Swal.fire({
            title: "<h2 style='color:#6BC698'>" + camp_name + "</h5>",
            html: 'Campaign Will Start in <b></b> seconds',
            timer: closeInSeconds*1000,
            timerProgressBar: true,
            allowOutsideClick: false,            
            didOpen: () => {
              Swal.showLoading()
              // const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                // closeInSeconds--;
                // b.textContent = closeInSeconds
              }, 1000)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer', data?.camp_details?.id)
              let camprunn_data = {'campaign_id': camp_id, 'agent_id':auth?.user_details?.useragent,'type':'start'}
              newSocket.send(JSON.stringify({
                data:camprunn_data,
                type: "call_customer"
            }));
            }
          })
      }

      if(type == "campaign_stop"){
        setCampaigndata(prev => {
          return {...prev, campaignstart:false,camp_details:null}
        })
      }

      if(type == "call_ended"){

      }
      
      if (type == "customer_details"){
        setCampaigndata(prev => {
          return {...prev, customer_data:data.customer_data}
        })
      }

      if(type == "no_customer"){
        setCampaigndata(prev => {
          return {...prev, NoCustomerpopUp:true}
        })
      }

      if(type ==  "no_campaigns_running"){
      
        Swal.fire({
          icon: 'warning',
          title: 'no campagains running calls'
        })
      }
  

      if(type == "campaign_completed"){

      }

      if(type == "campaign_stopeed"){

      }

      if(type == "incomingcall"){

      }

    });

    newSocket.onerror = e => {
        console.log('error', e)
    }
    
}, []);


// ** updating the contact centre agent's activity
  const changeAgentStatus = (status) =>{   

    if(['unavailable','break'].includes(status )){
        Toast.fire({
          icon: 'warning',
          title: 'You are not available to make calls'
        })
    }

    setActivity(prev => {
      return {...prev, agentonlineStatus:status}
    })


  }


// *** Updating the Agent Status Changes===================
  useEffect(()=>{


    alert("===========activity changes===",activity.agentonlineStatus)
    if (isAgentStatusFirstrun.current) {
      isAgentStatusFirstrun.current = false;
      return;
    }
    (async () => {

      if(activity.agentonlineStatus == "initial_status") {
        return;
      }

      const res_data = await fetchData('post',{'status':activity.agentonlineStatus, 'usertype':'agent'}, AGENT_ACTIVITY);

      console.log("AGENT STATUS 5555  : ",activity.agentonlineStatus)
           
      if (res_data?.data  == "success"){
        localStorage.setItem("onlineStatus", activity.agentonlineStatus);
        if(res_data?.alert_data && res_data?.type == "no_campaign"){

          Toast.fire({
            icon: 'warning',
            title: res_data?.alert_data
          })


        }
      } 
      else{
        alert("Error in Updating the agent status")
        setActivity(prev => {
          return {...prev, agentonlineStatus:"unavailable"}
        })
      }

  })();
  },[activity.agentonlineStatus])


  const sendMessagestoSocket = (data) =>{
    console.log("======Send Message to socket=====")
    socket.send(JSON.stringify({
      data:data,
      type: "changeAgentStatus"
  }));
  }



  return(
        <ActivityContext.Provider value={{ activity,socket, setActivity,campaigndata,changeAgentStatus }}>
            {children}
        </ActivityContext.Provider>
  )       
}

export default ActivityContext