import React,{useRef, useEffect, useState} from 'react'
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import axiosService from '../utils/axios';
import axios from '../utils/axios';

import { ReactComponent as LOCKICON } from '../assets/images/unlock1.svg'
import { ReactComponent as AVATARICON } from '../assets/images/avatar.svg'
import { ReactComponent as StoreIcon } from '../assets/images/logo/Logo.svg'

const LOGIN_URL = "/contactcentre/auth/login/";

const Login = () => {

const {setAuth} = useAuth();
const {} =useLogout();

const navigate = useNavigate();
const location = useLocation();
const from = location.state?.from?.pathname || "/";

const userRef = useRef();
const errRef = useRef();

const [user, setUser] = useState('');
const [password, setPassword] = useState('');
const [errormsg, setErrormsg] = useState('');

const logout = useLogout();


const Signout = async () =>{
  await logout('input');
}



useEffect(()=>{
    userRef.current.focus();
    Signout();
 
},[])

useEffect(()=>{
    setErrormsg('');
},['user','password'])


const handleSubmit = async (e) => {
    e.preventDefault();

    try {

        const response = await axiosService.post(LOGIN_URL,
            { 'username':user, password },
            { withCredentials: true }
        );

        const accessToken = response?.data?.access;


        const getUserresponse = await axios.post('/contactcentre/auth/agent/', {},
        {
            headers: { 'Content-Type': 'application/json' , 'Authorization':`Bearer ${accessToken} `},
            withCredentials: true
        })

        const roles = getUserresponse.data.roles
        const user_details = getUserresponse.data.user_details

        setAuth({ user, roles, accessToken ,user_details});
        setUser('');
        setPassword('');
        localStorage.setItem("IsLoggedIn", true);
        if(from == '/'){
            roles?.includes('contact-admin') ? navigate('/admin/dashboard', {replace:true}): navigate('/agent/dashboard', {replace:true});
        }
        else{
            console.log("==========NAVIGATE TO FROM LOACTION===", from)
            navigate(from, {replace:true});
        }

    } catch (err) {
        if (!err?.response) {
            setErrormsg('No Server Response');
        } else if (err.response?.status === 400) {
            setErrormsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrormsg('Unauthorized');
        } else {
            setErrormsg('Login Failed');
        }
        errRef.current.focus();
    }
}





  return (

    <>

<img src={require('../assets/images/wave.jpg')} style={{zIndex:'-1'}} className="fixed hidden lg:block inset-0 h-full" />

    <div className="w-screen h-screen flex flex-col justify-center items-center lg:grid lg:grid-cols-2" >
    
    <LOCKICON   className="hidden fill:black lg:block w-86 h-86 scale-150 hover:scale-110 transition-all duration-500 transform mx-auto"/>
      <form className="flex flex-col justify-center items-center lg:w-1/2" onSubmit={handleSubmit}>

      <img src={require('../assets/images/avatar.png')} className="w-32 h-32" />
    
        <div className="flex gap-x-4 items-center mb-[50px]">
          <StoreIcon className={`cursor-pointer duration-500 `}/>
          <h1 className="my-8 font-display font-bold text-xl lg:text-3xl text-gray-700 text-center">
            CONTACT CENTRE
            </h1>
            
        </div>

        <div className="relative">
          <i className="fa fa-user absolute text-primarycolor text-xl"></i>
          <input 
                type="text" 
                name="username" 
                id="username"
                className="w-full py-2 px-4  pl-8 rounded h-19 border-b-2 font-display focus:outline-none focus:border-primarycolor transition-all duration-500 capitalize text-lg"
                ref={userRef}
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
            />
        </div>
        <div className="relative mt-8">
          <i className="fa fa-lock absolute text-primarycolor text-xl"></i>
              <input 
                type="password" 
                name="password" 
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                className="w-full py-2 px-4  pl-8 rounded h-19 border-b-2 font-display focus:outline-none focus:border-primarycolor transition-all duration-500 capitalize text-lg"
                value={password}
                required />


        </div>
        {/* <a href="#" className="self-end mt-4 text-gray-600 font-bold"
          >Forgot password?</a> */}


        <button 
        className="py-3 px-20 bg-primarycolor rounded-full text-white font-bold uppercase text-lg mt-4 transform hover:translate-y-1 transition-all duration-500">
          Login</button>

        <div ref={errRef} className={`bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 ${errormsg?"block":"hidden"}`} role="alert">
          <strong className="font-bold">Login Failed!</strong>
          <span className="block sm:inline">{errormsg}</span>
        </div>
      </form>

    </div>

    </>
  )
}

export default Login
