import React, { useState, useEffect } from "react";
import CustomerList from "./CustomerList";
import { CamptheadData } from "../../utils/Data";
import CampaignList from "./CampaignList";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ManuvalListCamp from "./ManuvalListCamp";
import AgentCampDetailReport from "./AgentCampDetailReport";
import CampaignReport from "../Reports/CampaignReport";

const AgentCampReport = () => {
  const [curtab, setcurtab] = useState("campList");

  return (
    <div>
      <ul className="flex pb-4">
        <li className="flex-1 mr-2" onClick={() => setcurtab("campList")}>
          <Link
            to="/agent/campaigns/list/"
            className={` ${
              curtab === "campList"
                ? "bg-gradient-to-r from-[#59e370] via-[#7ecfa6] to-[#4ec8ce]"
                : " hover:bg-gray-600 text-black hover:text-white border-white bg-gray-200"
            } py-2 px-4  text-center block border rounded text-white`}
          >
            Campaign List
          </Link>
        </li>
        <li className="flex-1 mr-2" onClick={() => setcurtab("report")}>
          <Link
            to="/agent/campaigns/report/"
            className={` ${
              curtab === "report"
                ? "bg-gradient-to-r from-[#59e370] via-[#7ecfa6] to-[#4ec8ce]"
                : " hover:bg-gray-600 text-black hover:text-white border-white bg-gray-200"
            } py-2 px-4  text-center block border rounded text-white`}
          >
            Campaign Report
          </Link>
        </li>
        <li className="flex-1 mr-2" onClick={() => setcurtab("customer")}>
          <Link
            to="/agent/campaigns/customer/"
            className={` ${
              curtab === "customer"
                ? "bg-gradient-to-r from-[#59e370] via-[#7ecfa6] to-[#4ec8ce]"
                : " hover:bg-gray-600 text-black hover:text-white border-white bg-gray-200"
            } py-2 px-4  text-center block border rounded text-white`}
          >
            Customer List
          </Link>
        </li>

        {/* <li className="flex-1 mr-2" onClick={()=>setcurtab("manuval-customer-list")}>
            <Link to="/agent/campaigns/manuval-customer-list/" className={` ${ curtab == "manuval-customer-list" ? 'bg-gradient-to-r from-[#59e370] via-[#7ecfa6] to-[#4ec8ce]': ' hover:bg-gray-600 text-black hover:text-white border-white bg-gray-200' } py-2 px-4  text-center block border rounded text-white`} >Manuval Campaign List</Link>
          </li> */}
      </ul>

      <div className="bg-white p-4 h-screen">
        {curtab === "campList" && (
          <CampaignList
            theadData={CamptheadData}
            user_type="agent"
            camp_search={true}
          />
        )}
        {curtab === "report" && <CampaignReport type="agent" edit_fb={true} />}
        {curtab === "customer" && (
          <CustomerList user_type="agent" edit_fb={true} />
        )}
      </div>
    </div>
  );
};

export default AgentCampReport;
