import React from 'react'

import { Link } from 'react-router-dom';



const Unauthorized = () => {

  return (

      <div className="min-h-screen bg-white flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Unauthorized</h2>
        <p className="mt-2 text-center text-md text-gray-600">You do not have permission to access this page.</p>
        <div className="mt-4 flex justify-center">
          <Link
            to="/login/"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:black"
            style={{
              backgroundImage:
                "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
              color: "white",
            }}
          >
            Go to Home
          </Link>




         


        </div>
      </div>
      </div>
  )
}

export default Unauthorized