import { useEffect, useState } from "react";
import { axiosPrivate } from "../../utils/axios";
import useAuth from "../../hooks/useAuth";

function CallType(props) {
  console.log("<<<<<<<<<", props, "?????????????");

  // user id //
  const user = useAuth();
  const { auth } = useAuth;
  const user_id = user.auth.user_details.id;

  const sendCallTypeToNewCampaign = () => {
    if (props.callType !== "") {
      props.setActive(props.next);
    }
  };

  const handleChange = (event) => {
    props.setData((oldArray) => {
      return { ...oldArray, call_type: event.target.value };
    });
  };

  //  Customer Phone Number Masking Permission //
  const [isChecked, setIsChecked] = useState(false);

  console.log("<<<<<<<<<",isChecked)


  const handelcustomerNumberMaskingPermission = (event) => {
    setIsChecked(event.target.checked);
    props.setData((oldArray) => {
      return { ...oldArray, customerNumberMask: event.target.checked };
    });
  };

  const handleCallIntervalTimeTimeChange = (event) => {
    props.setData((oldArray) => {
      return { ...oldArray, campaignStartTime: event.target.value };
    });
  };

  const handleNextCallIntervalTimeTimeChange = (event) => {
    props.setData((oldArray) => {
      return { ...oldArray, nextCallTime: event.target.value };
    });
  };

  const handleMaximumRetryChange = (event) => {
    props.setData((oldArray) => {
      return { ...oldArray, maxRetry: event.target.value };
    });
  };

  const handleDidChange = (event) => {
    props.setData((oldArray) => {
      return { ...oldArray, did: event.target.value };
    });
  };

  const [progressiveDialer, setProgressiveDialer] = useState(
    props.progressiveDialer
  );
  const [dids, setDid] = useState([]);

  const getDids = async () => {
    const response = await axiosPrivate.get(
      "/contactcentre/get_did/" + user_id + "/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      }
    );
    setDid(response.data);
  };

  const handleCheckboxChange = () => {
    console.log("Progressive Dialer    :  ", progressiveDialer);
    setProgressiveDialer(!progressiveDialer); // Toggle the checkbox status
    getDids();
    if (progressiveDialer == true) {
      props.setData((oldArray) => {
        return { ...oldArray, did: null };
      });
    }
  };

  useEffect(() => {
    props.setData((oldArray) => {
      return { ...oldArray, progressiveDialer: progressiveDialer };
    });
  }, [progressiveDialer]);
  return (
    <div>
      <p className="text-sm text-slate-400">Step 4/6</p>
      <h1 className="text-lg font-bold">Let's Select Call Type</h1>
      <p className="text-sm text-slate-400 border-b-2 pb-8">
        Please select type, so that we can create new campaign
      </p>
      <h1 className="text-md text-slate-400 pt-8">Call Type</h1>
      <div className="relative w-full flex space-x-2">
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[18rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={props.callType}
          onChange={handleChange}
        >
          <option></option>
          <option value={"auto"}>Auto</option>
          <option value={"manual"}>Manual</option>
        </select>
        <div className="w-[15rem]">
          <input
            type="text"
            id="campaign_time_interval"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Campaign Start Time"
            onChange={handleCallIntervalTimeTimeChange}
            value={props.campaignStartTime}
            required
          />
        </div>
        {progressiveDialer ? (
          <div className="w-[15rem]">
            <input
              type="text"
              id="maximum_retry_calls"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Maximum retry"
              onChange={handleMaximumRetryChange}
              value={props.maxRetry}
              required
            />
          </div>
        ) : (
          <div></div>
        )}
        {props.callType === "auto" ? (
          <div className="w-[15rem]">
            <input
              type="text"
              id="next_call_time_Interval"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Next Call Start Time"
              onChange={handleNextCallIntervalTimeTimeChange}
              value={props.nextCallTime}
              required
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="flex space-x-6">
        <h1 className="text-md text-slate-400 pt-8">Customer Number Masking</h1>
        <div className="flex items-center mb-4">
          <input
            id="checkbox"
            type="checkbox"
            checked={props.customerNumberMask}
            onChange={handelcustomerNumberMaskingPermission}
            className="w-6 h-6 mt-8 text-blue-600 bg-gray-100 border-gray-300 rounded-md focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>
      </div>
      {props.callType === "auto" ? (
        <div className="flex space-x-4 mt-4">
          <div>
            <input
              id="default-checkbox"
              type="checkbox"
              checked={progressiveDialer}
              onChange={handleCheckboxChange}
              className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />

            <label
              htmlFor="default-checkbox"
              className="ms-2 ml-5 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Progressive Dialer
            </label>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {/* Enter Did  */}
      {progressiveDialer ? (
        <div className="mt-4 w-[18rem]">
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[18rem] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            id="did"
            value={props.did}
            onChange={handleDidChange}
          >
            <option value={""}>{props.did}</option>
            {dids.map((did) => (
              <option key={did} value={did} name="did">
                {did}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div></div>
      )}
      <div className="pt-8 flex justify-end items-end">
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          onClick={() => {
            props.setActive(props.back);
          }}
        >
          Back
        </button>
        <button
          className="font-bold text-lg rounded-full w-36 h-12"
          style={{
            backgroundImage:
              "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
            color: "white",
          }}
          onClick={sendCallTypeToNewCampaign}
        >
          Next Step
        </button>
      </div>
    </div>
  );
}
export default CallType;
