import React from 'react'
import { useEffect, useState } from "react";
import UseAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

const useFetch = (props) => {

    const axiosprivate = UseAxiosPrivate("axios fetch");
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState({});
    const { auth } = useAuth();

    const fetchData = async (method, post_data, url) => {
            setStatus('fetching');
            console.log("==============fetching data==========", method, post_data, url)
            const response = await axiosprivate.post(url, post_data,
            {
                headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${auth?.accessToken}`  },
                withCredentials: true
            })

            const data = response.data;
            
            console.log("===featch hook response data==",method, post_data, url, data)
            setData(data);
            setStatus('fetched');
            return data
        };

    return status, data, fetchData;

}

export default useFetch


