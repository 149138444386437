import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AgentChart = ({ label, called, tobe_called }) => {
  if (label === undefined) {
    label = [];
  }

  console.log("<<<<<<<<<<<<<<<<<<<<LABEL  :  ",label)
  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          color: "#9AA7B5",
          font: {
            size: 15,
            family: "karla",
          },
          padding: 30,
        },
      },
      tooltip: {
        enabled: label.length > 0,
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        borderColor: "black",
        borderWidth: "2",
      },
    },
    responsive: true,

    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },

        ticks: {
          color: "#232323",
          callback: function (value) {
            let characterLimit = 6;
            let label_val = label[value];
            if (label_val.length >= characterLimit) {
              return (
                label_val
                  .slice(0, label_val.length)
                  .substring(0, characterLimit - 1)
                  .trim() + ".."
              );
            }
            return label_val;
          },

          font: {
            size: 15,
            family: "karla",
            weight: "600",
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#232323",
          font: {
            size: 15,
            family: "karla",
            weight: "600",
          },
        },
      },
    },

    maintainAspectRatio: false,
  };

  const data = () => {
    return {
      labels: label,
      datasets: [
        {
          label: "Called",
          data: called,
          skipNull: true,
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 270);
            gradient.addColorStop(0, "#AFD462");
            gradient.addColorStop(0.7, "#6BC698");
            gradient.addColorStop(1, "#0FB1E2");
            return gradient;
          },
          barThickness: 48,
        },
        {
          label: "To be Called",
          data: tobe_called,
          backgroundColor: "#D5D8DC",
          barThickness: 48,
          borderRadius: 5,
        },
      ],
    };
  };

  return <Bar data={data()} options={options} id="canvas" />;
};

export default AgentChart;
