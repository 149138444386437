// svg //
import { ReactComponent as CampaignName } from "../../assets/images/svg/CampaignName.svg";
import { ReactComponent as SelectAgent } from "../../assets/images/svg/Agent.svg";
import { ReactComponent as ScheduleTime } from "../../assets/images/svg/ScheduleTime.svg";
import { ReactComponent as CallType } from "../../assets/images/svg/Call.svg";
import { ReactComponent as Feedback } from "../../assets/images/svg/Feedback.svg";
import { ReactComponent as Upload } from "../../assets/images/svg/FileUpload.svg";

// import { ReactComponent as VerticalConnection } from "../assets/images/svg/VerticalCannection.svg";

// Components //
import Campaignname from "../../components/CreateCampaign/CampaignName";
import Selectagent from "../../components/CreateCampaign/SelectAgent";
import Scheduletime from "../../components/CreateCampaign/ScheduleTime";
import FeedBack from "../../components/CreateCampaign/FeedBack";
import UploadCustomer from "../../components/CreateCampaign/UploadCustomer";
import Calltype from "../../components/CreateCampaign/CallType";

import CreateCampaign from "../Admin/CreateCampaign";

import Swal from "sweetalert2";

// Use State //
import { useEffect, useState } from "react";

// Axios //
import useAuth from "../../hooks/useAuth";
import UseAxiosPrivate from "../../hooks/useAxiosPrivate";
import axiosService from "../../utils/axios";
import Labels from "../../components/CreateCampaign/Labels";
import axios from "axios";

// Location//
import { useNavigate, useLocation } from "react-router-dom";
const CAMPAIGN_URL = "/contactcentre/NewAddCampaign/";

const NewCampaign = (props) => {
  const navigate = useNavigate();

  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const campaignId = pathArray.pop();

  console.log(campaignId, "location1111111");

  // Set Error Maessage //
  const [errormsg, setErrormsg] = useState("");

  // user id //
  const user = useAuth();
  const user_id = user.auth.user_details.id;

  // ========================  Getting Angets ============================ //

  // Agnet users //
  const [agent, agentData] = useState({});

  const getAgnetNames = async () => {
    const response = await axiosprivate.get(
      "/contactcentre/agent_names/" + user_id + "/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.accessToken}`,
        },
        withCredentials: true,
      }
    );
    agentData(response.data);
  };

  // ======================== End  Getting Angets ============================ //

  // Active page
  const [active, setActive] = useState("CampaignName");

  const handleChange = (e) => {
    getAgnetNames();
    setActive(e);
    console.log("ACTIVE -----", active);
  };

  // Data for send to backend //
  const [data, setData] = useState({
    campaignId: "",
    campaign_name: "",
    agents: [],
    schedule_time: "",
    call_type: "",
    questions: [],
    file: "",
    labels: [],
    customerNumberMask: false,
    did: "",
    campaignStartTime: "",
    nextCallTime: "",
    maxRetry: "",
    progressiveDialer: false,
  });

  useEffect(() => {
    getAgnetNames();
  }, [active]);

  //######### Send Campaign Data to Backend #########//

  const { auth, setAuth } = useAuth();
  const axiosprivate = UseAxiosPrivate();

  const sendCampaignDataToBackend = async () => {

    try {
      const formData = new FormData();
      formData.append("userId", user_id);
      formData.append("camid", campaignId);
      formData.append("campaignName", data.campaign_name);
      formData.append("virtualuserids", data.agents);
      formData.append("scheduledTime", data.schedule_time);
      formData.append("campaign_mode", data.call_type);
      formData.append("questions", JSON.stringify(data.questions));
      formData.append("file", data.file);
      formData.append("labeloption", JSON.stringify(data.labels));
      formData.append("customerNumberPermission", JSON.stringify(data.customerNumberMask))
      formData.append("did", data.did);
      formData.append("campaignStartTime", data.campaignStartTime);
      formData.append("nextCallTime", data.nextCallTime);
      formData.append("maxRetry", data.maxRetry);

      const response = await axiosService.post(CAMPAIGN_URL, formData);
      if (response.data.status === "1") {
        Swal.fire({
          title: "Success",
          text: "Campaign Created",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Move to Campaign List",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/admin/campaign/list");
          }
        });
      } else if (response.data.status === "2") {
        Swal.fire({
          title: "Success",
          text: "Campaign Updated",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Move to Campaign List",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/admin/campaign/list");
          }
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response.data.error,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("");
          }
        });
      }
    } catch (err) {
      if (!err?.response) {
        setErrormsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrormsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrormsg("Unauthorized");
      }
    }
  };

  const UpdateCampaign = async () => {
    let camp_update_data = {
      user_id: auth?.user_details?.user,
      campaignName: campaignId,
    };
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth?.accessToken}`,
      },
    };
    await axios
      .post(
        process.env.REACT_APP_API_URL + "/contactcentre/get_campaign_data/",
        camp_update_data,
        config
      )
      .then((res) => {
        console.log(
          "<<<<<<<<<<<<<<<<<<<<<<<<<<<",
          res.data.time,
          ">>>>>>>>>>>>>>>>"
        );
        setData({
          ...data,
          campaign_name: res.data.campaignName,
          agents: res.data.agents,
          schedule_time: res.data.scheduleTime,
          call_type: res.data.callType,
          file: res.data.file,
          questions: res.data.questions,
          labels: res.data.labels,
          customerNumberMask: res.data.customerNumPermission,
          did: res.data.did,
          campaignStartTime: res.data.call_interval_time,
          nextCallTime: res.data.next_call_time,
          maxRetry: res.data.maxRetry,
          progressiveDialer: res.data.progressive_dialer,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (campaignId !== "0") {
      UpdateCampaign();
    }
  }, []);

  return (
    <div className="p-4 bg-back-white">
      {/* <div className="space-y-2">
        <h1 className="font-bold text-lg">New Campaign</h1>
        <p className="text-md text-slate-400">
          Follow the simple steps to add new campaign
        </p>
      </div> */}
      <div className="antialiased p-4 mt-6 grid grid-cols-4">
        <div className="flex sm:space-x-0 md:space-x-6 col-span-1">
          <ul className="space-y-12 mt-4 flex-shrink-0 relative hidden dark:border-primary-darker dark:bg-darker md:block overflow-y-auto no-scrollbar">
            <li className="text-slate-400 font-bold text-md p-3">
              Campaign Name
            </li>
            <li className="text-slate-400 font-bold text-md p-3">
              Select Agent
            </li>
            <li className="text-slate-400 font-bold text-md p-3 pt-[17px]">
              Schedule Time
            </li>
            <li className="text-slate-400 font-bold text-md p-3 pt-[13px]">
              Call Type
            </li>
            <li className="text-slate-400 font-bold text-md p-3 pt-[10px]">
              Upload Customers
            </li>

            <li className="text-slate-400 font-bold text-md p-3 pt-[22px]">
              Feedback Questions
            </li>
          </ul>
          <ul className="mt-4 h-full w-full">
            <div
              key={"CampaignName"}
              className="select-none bg-gray-300 p-3 rounded-full h-10 w-10 flex justify-center items-center border-slate-900 border-spacing-6"
              onClick={() => handleChange("CampaignName")}
            >
              {active === "CampaignName" ? (
                <CampaignName fill="#01B375" />
              ) : (
                <CampaignName fill="#919197" />
              )}
            </div>
            <div className="h-[60px] bg-[#BFC7CD] w-[0.5px] ml-[19px]"></div>
            <div
              key={"SelectAgent"}
              className="bg-gray-300 rounded-full w-10 h-10 p-3 flex justify-center items-center"
              onClick={() => handleChange("SelectAgent")}
            >
              {active === "SelectAgent" ? (
                <SelectAgent
                  fill="#01B375"
                  agent={agent}
                  setData={setData}
                  agents={data.agents}
                />
              ) : (
                <SelectAgent fill="#919197" />
              )}
            </div>
            <div className="h-[60px] bg-[#BFC7CD] w-[0.5px] ml-[19px]"></div>
            <div
              key={"ScheduleTime"}
              className="bg-gray-300 rounded-full w-10 h-10 p-3 flex justify-center items-center"
              onClick={() => handleChange("ScheduleTime")}
            >
              {active === "ScheduleTime" ? (
                <ScheduleTime fill="#01B375" />
              ) : (
                <ScheduleTime fill="#919197" />
              )}
            </div>
            <div className="h-[58px] bg-[#BFC7CD] w-[0.5px] ml-[19px]"></div>
            <div
              key={"CallType"}
              className="bg-gray-300 rounded-full w-10 h-10 p-3 flex justify-center items-center"
              onClick={() => handleChange("CallType")}
            >
              {active === "CallType" ? (
                <CallType fill="#01B375" />
              ) : (
                <CallType fill="#919197" />
              )}
            </div>
            <div className="h-[58px] bg-[#BFC7CD] w-[0.5px] ml-[19px]"></div>
            <div
              key={"Upload"}
              className="bg-gray-300 rounded-full w-10 h-10 p-3 flex justify-center items-center"
              onClick={() => handleChange("Upload")}
            >
              {active === "Upload" ? (
                <Upload fill="#01B375" />
              ) : (
                <Upload fill="#919197" />
              )}
            </div>
            <div className="h-[58px] bg-[#BFC7CD] w-[0.5px] ml-[19px]"></div>
            <div
              key={"Labels"}
              className="bg-gray-300 rounded-full w-10 h-10 p-3 flex justify-center items-center"
              onClick={() => handleChange("Labels")}
            >
              {active === "Labels" ? (
                <Feedback fill="#01B375" />
              ) : (
                <Feedback fill="#919197" />
              )}
            </div>
          </ul>
        </div>
        <div className="col-span-3">
          <div>
            {active === "CampaignName" && (
              <Campaignname
                setActive={setActive}
                next="SelectAgent"
                back={props.SetOpenNewCampaign}
                setData={setData}
                agentData={agentData}
                cName={data.campaign_name}
              />
            )}

            {active === "SelectAgent" && (
              <Selectagent
                setActive={setActive}
                next="ScheduleTime"
                back="CampaignName"
                agent={agent}
                setData={setData}
                agents={data.agents}
              />
            )}

            {active === "ScheduleTime" && (
              <Scheduletime
                setActive={setActive}
                next="CallType"
                back="SelectAgent"
                setData={setData}
                scheduleTime={data.schedule_time}
              />
            )}
            {active === "CallType" && (
              <Calltype
                setActive={setActive}
                next="Upload"
                back="ScheduleTime"
                setData={setData}
                callType={data.call_type}
                customerNumberMask={data.customerNumberMask}
                progressiveDialer={data.progressiveDialer}
                nextCallTime={data.nextCallTime}
                campaignStartTime={data.campaignStartTime}
                time={data.time}
                did={data.did}
                maxRetry={data.maxRetry}
              />
            )}

            {active === "Upload" && (
              <UploadCustomer
                setActive={setActive}
                back="CallType"
                next="Labels"
                setData={setData}
                feedBack={data.questions}
                file={data.file}
              />
            )}

            {active === "Labels" && (
              <Labels
                setActive={setActive}
                back="Upload"
                setData={setData}
                sendCampaignDataToBackend={sendCampaignDataToBackend}
                SetOpenNewCampaign={props.SetOpenNewCampaign}
                labels={data.labels}
                questions={data.questions}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewCampaign;
