import React, { useState } from "react";
import useLogout from "../hooks/useLogout";
import useAuth from "../hooks/useAuth";

const Header = (props) => {
  const [isuserMenuOpen, setisuserMenuOpen] = useState(false);
  const logout = useLogout();
  const { auth } = useAuth();

  let type = "";
  auth.roles?.includes("contact-admin") ? (type = "admin") : (type = "agent");

  const activity_color_status = {
    available: "bg-green-400",
    unavailable: "bg-red-400",
    break: "bg-blue-400",
  };

  // ** update agent activity in server

  const signout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const openNotificationsPanel = () => {
    alert("open notification panel");
  };
  const openSearchPanel = () => {
    alert("open openSearchPanel panel");
  };

  const openNotificationsPael = () => {
    alert("open notification panel");
  };

  return (
    <header className="relative">
      <div className="flex items-center justify-between h-[52px]">
        <div className="flex items-center justify-between gap-2">
          <button
            onClick={() => props.openSidebar()}
            className="p-1 transition-colors duration-200 rounded-md md:hidden  text-primary-lighter "
          >
            <span className="sr-only">Open main manu</span>
            <span aria-hidden="true">
              <svg
                className="w-8 h-8 stroke-[#7B8897] hover:stroke-cyan-700"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </span>
          </button>
          <a className="inline-block text-[26px] font-[700] tracking-wider text-primary-dark dark:text-light md:text-2xl">
            {props.currsidemenu}
          </a>
        </div>

        <nav aria-label="Secondary" className="gap-2 flex items-center">
          {type == "agent" ? (
            <div
              className={`h-4 w-4 rounded-full ${
                activity_color_status[props.onlineStatus]
              }`}
            ></div>
          ) : (
            <div
              className={`h-4 w-4 rounded-full ${activity_color_status["available"]}`}
            ></div>
          )}
          <div className="hidden">
            <div className="bg-blue-400"></div>
            <div className="bg-red-400"></div>
            <div className="bg-green-400"></div>
          </div>

          {/* <button onClick={openNotificationsPanel} className="p-2 transition-colors duration-200 rounded-full  bg-white ">
                    <span className="sr-only">Open Notification panel</span>
                    <svg
                      className="w-7 h-7 stroke-[#7B8897] hover:stroke-cyan-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </button>
                 */}
          {/* <button onClick={openSearchPanel} className="p-2 transition-colors duration-200 rounded-full  bg-white "
                  >
                    <span className="sr-only">Open search panel</span>
                    <svg
                      className="w-7 h-7 stroke-[#7B8897] hover:stroke-cyan-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </button> */}

          <p className="font-karla font-[900] text-teal-500">
            {type === "agent"
              ? auth?.user_details.agent_username
              : auth?.user_details.username}
          </p>

          <div className="relative" x-data="{ open: false }">
            <button
              onClick={() => setisuserMenuOpen(!isuserMenuOpen)}
              type="button"
              className={`transition-opacity duration-200 rounded-full `}
            >
              <span className="sr-only">User menu</span>
              <img
                src={require("../assets/images/user.png")}
                className="w-10 h-10 block lg:inline-block"
              />
            </button>

            <div
              className={` ${
                isuserMenuOpen ? "absolute" : "hidden"
              } right-0 w-48 py-1 bg-white rounded-md shadow-lg top-12 ring-1 ring-black ring-opacity-5 dark:bg-dark focus:outline-none transition-transform duration-300 xl:translate-x-0 z-50`}
              tabIndex="-1"
              role="menu"
              aria-orientation="vertical"
              aria-label="User menu"
            >
              <a
                href="#"
                role="menuitem"
                onClick={() => setisuserMenuOpen(false)}
                className="block px-4 py-2 text-sm text-gray-700 transition-colors hover:bg-gray-100 dark:text-light dark:hover:bg-primary"
              >
                Your Profile
              </a>

              {type == "agent" ? (
                <>
                  <a
                    onClick={() => {
                      props.setOnlineStatus("available");
                      setisuserMenuOpen(false);
                    }}
                    href="#"
                    role="menuitem"
                    className={`block px-4 py-2 ${
                      props.onlineStatus == "available" ? (
                        "rounded-lg " + activity_color_status["available"]
                      ) : (
                        <></>
                      )
                    }   text-sm text-gray-700 hover:rounded-lg hover:bg-green-400 mb-1`}
                  >
                    Available
                  </a>

                  <a
                    onClick={() => {
                      props.setOnlineStatus("unavailable");
                      setisuserMenuOpen(false);
                    }}
                    href="#"
                    role="menuitem"
                    className={`block px-4 py-2 ${
                      props.onlineStatus == "unavailable" ? (
                        "rounded-lg" + activity_color_status["unavailable"]
                      ) : (
                        <></>
                      )
                    } text-sm text-gray-700  hover:rounded-lg hover:bg-red-400 mb-1`}
                  >
                    UnAvailable
                  </a>
                  <a
                    onClick={() => {
                      props.setOnlineStatus("break");
                      setisuserMenuOpen(false);
                    }}
                    href="#"
                    role="menuitem"
                    className={`block px-4 py-2 ${
                      props.onlineStatus == "break" ? (
                        "rounded-lg " + activity_color_status["break"]
                      ) : (
                        <></>
                      )
                    } text-sm text-gray-700 hover:rounded-lg  hover:bg-blue-400 mb-1`}
                  >
                    Break
                  </a>
                </>
              ) : (
                <></>
              )}

              <a
                onClick={() => {
                  signout();
                  setisuserMenuOpen(false);
                }}
                href="#"
                role="menuitem"
                className="block px-4 py-2 text-sm text-g-700 transition-colors hover:bg-gray-100"
              >
                Logout
              </a>
            </div>
          </div>
        </nav>
      </div>
      <div></div>
    </header>
  );
};

export default Header;
