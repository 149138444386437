import { axiosPrivate } from "../utils/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { Navigate, useNavigate } from "react-router-dom";

const UseAxiosPrivate = (type="empty") => {

    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const navigate = useNavigate();


    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                console.log("==============request url axios private=====", config.url)
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            }
            , (error) => {
                Promise.reject(error)
            }
        );


        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                console.log("========ERROR IN THE REQUEST RESPONSE====", error)

                const prevRequest = error?.config;
                // console.log("==RESPONSE CODE===", error?.response?.status, prevRequest?.sent)

                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                
                return Promise.reject(error);
            }
        );


        return () => {

            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
        
    }, [auth, refresh])

    return axiosPrivate;
}

export default UseAxiosPrivate;