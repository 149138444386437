import { yellow } from "tailwindcss/colors"

export const ROLES = {
    'Agent': 'contact-agent',
    'Admin': 'contact-admin'
  }
  

export const AGENT_STATUS_COLOR_CODE =  {
    "unavailable":"red",
    "logout":"yellow",
    "login":"purple",
    "available":"green",
    "other":"pink",
    "break":"blue"
  }

export const CamptheadData = ["Sl-No", "Campaign Name", "Status","Campaign Mode", "Total Customers", "Called", "To be Called", "No of Assigned Agents", "Scheduled Time"];