import useAuth from "./useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import axiosService from "../utils/axios";

const useLogout = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const LOGOUT_URL = "/contactcentre/auth/logout/";

  const logout_final = async () => {
    try {
      const response = await axiosService.post(
        LOGOUT_URL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.accessToken}`,
          },
          withCredentials: true,
        }
      );
    } catch (err) {}

    // setAuth({});
    localStorage.setItem("IsLoggedIn", false);
    localStorage.removeItem("onlineStatus");
    navigate("/login", { replace: true });
  };

  const logout = (type) => {
    if (type == "input") {
      console.log(type)
    } else {
      Swal.fire({
        title: "Are you sure to Logout?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Logout",
      }).then((result) => {
        if (result.isConfirmed) {
          logout_final();
        }
      });
    }
  };

  return logout;
};
export default useLogout;
