import { useEffect, useState } from "react";
import { ReactComponent as Cross } from "../../assets/images/svg/Cross.svg";
import { ReactComponent as Recall } from "../../assets/images/svg/ReCall.svg";

function Feedback02(props) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    console.log("-------------", props, "------------");
    setQuestions(props.questions);
  }, [props.questions]);

  const AnswesAppend = (question, event) => {
    props.setFBdata((prevState) => ({
      ...prevState,
      answers: { ...prevState.answers, [question]: event.target.value },
    }));
  };

  return (
    <>
      {questions.map((question, index) => (
        <div key={question}>
          <h1 className="text-gray-400 text-md">{question}</h1>
          <input
            type="text"
            id="Answer"
            value={props.answers[question] || ""}
            onChange={(event) => {
              AnswesAppend(question, event);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-full h-20 p-2.5  dark:border-black dark:text-white"
            required
            disabled={props?.viewfb || false}
          />
        </div>
      ))}

      <div className="flex place-content-between">
        <div className=""></div>

        <div className="">
          <button
            onClick={() => {
              props.setcurrfback(props.back);
            }}
            className="font-bold text-lg rounded-full w-36 h-12 mt-12 items-end"
          >
            Back
          </button>

          <button
            onClick={() => {
              props.setcurrfback(props.next);
            }}
            className="font-bold text-lg rounded-full w-36 h-12 mt-12 items-end"
            style={{
              backgroundImage:
                "linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 82.06%)",
              color: "white",
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
export default Feedback02;
