import { useEffect, useState } from "react";
import Pagination from "../../components/Table/pagination";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";

import { ReactComponent as Cross } from "../../assets/images/svg/Cross.svg";
import { ReactComponent as Recall } from "../../assets/images/svg/ReCall.svg";

function AgentCampDetailReport(props) {
  const [camplist, setCampList] = useState([
    {
        "id": 47,
        "name": "TESTCAMP APR5",
        "customer_type": "project",
        "campaign_mode": "auto",
        "campaign_status": "stop",
        "campaign_call_mode": "mobilephone",
        "scheduled_datetime": "2023-04-05T11:22:00",
        "call_intervel_time": "10",
        "created_at": "2023-04-05T11:22:59.845151",
        "updated_at": "2023-04-05T14:12:38.886333",
        "user": 17,
        "agents": [
            14,
            15
        ]
    }
]);
  const { auth } = useAuth();

  const BASE_URL = process.env.REACT_APP_API_URL

  let user_id = auth?.user_details?.useragent


  useEffect(() => {
    axios.get(BASE_URL+"/contactcentre/CampaignListPerVirtualSIPUser/"+user_id+"/").then((response) => {
      setCampList(response.data);
    
      console.log("====CAMP LIST====",response.data, camplist)
   

    });
}, [])














  // SHOW FEEDBACK POPUP //

  const [showPopUp, setshowPopUp] = useState(false);

  // CLOSE FEEDBACK POPUP//
  const handleOnClose = () => {
    setshowPopUp(false);
  };


  const [campaignRepotData, setCampaignReportData] = useState([]);

  const [responseData, setResponseData] = useState([]);

  // useEffect(() => {
  //   const fetchCampaignReport = async () => {
  //     let post_data;
  //     if (props.user_type == "agent") {
  //       post_data = {
  //         user_id: auth?.user_details?.useragent,
  //         user_type: auth?.user_details?.type,
  //         download: "yes",
  //       };
  //     } else {
  //       post_data = {
  //         user_id: auth?.user_details?.user,
  //         user_type: auth?.user_details?.type,
  //         download: "yes",
  //       };
  //     }

  //     let config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${auth?.accessToken}`,
  //       },
  //     };

  //     await axios
  //       .post(
  //         process.env.REACT_APP_API_URL + "/contactcentre/campaign_report/",
  //         post_data,
  //         config
  //       )
  //       .then((res) => {
  //         setCampaignReportData(res.data);
  //         setResponseData(res.data);
  //         console.log("=camp list loaded=", res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   };
  //   fetchCampaignReport();
  // }, []);

  const [FeedbackData, setFeedBackData] = useState({});

  const Feedback = (e) => {
    setshowPopUp(true);
    const Data = campaignRepotData.filter((item) => item.EventId === e);
    setFeedBackData(Data);
  };

  //  SEARCH //
  const [searchData, setSearchData] = useState([]);

  const Search = (event) => {
    let search_text = event.target.value.split("");
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      console.log("[[[[[[[[[[", event.target.value, "]]]]]]]]]]]]]]]");
      search_text = event.target.value.split("");
    }
    const Data = campaignRepotData.filter((item) =>
      search_text.every((char) => item.AgentNames.includes(char))
    );
    setSearchData(Data);
  };

  useEffect(() => {
    if (searchData.length === 0 || searchData === []) {
      console.log(searchData, "=====");
      setCampaignReportData(responseData);
    } else {
      setCampaignReportData(searchData);
    }
  }, [searchData]);
  
  const api =
    "https://example.com/api/uploads/downloadedWaveFile/voice-recording-file.wav";

  const Download = () => {
    console.log(
    );
  };

  return (
    <div className="bg-white p-4">
      <div className="flex flex-col">
        <div className="flex justify-between p-3 gap-4">
          <div className="flex justify-items-start gap-6">
            <div className="">
              <label htmlFor="table-search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  id="table-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for items"
                  onChange={Search}
                />
              </div>
            </div>
            <div className="relative w-full flex space-x-2">
              <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value="all"> All Campaigns</option>
                <option value="all"> All1 Campaigns</option>

                {camplist.map((campaign, index) => {
                  <option value="dsfdsf">"sdfsdgdfg"</option>;
                })}

              </select>
            </div>
          </div>
        </div>

        <div>
          <CampaignFeedBack
            onClose={handleOnClose}
            visible={showPopUp}
            data={FeedbackData}
          />
        </div>

        <div className="py-2 inline-block min-w-full ">
          <div className="overflow-x-auto">
            <table className="w-full ml-[-4rem]">
              <thead>
                <tr className="text-[#9AA7B5] text-[17px] font-karla font-[500]">
                  <td className="pl-[6.0rem] pr-[2.1rem]  py-4 text-left">
                    Sl
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Customer
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Call Duration
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Call Status
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    View Feedback
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Scheduled Time
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Play/Pause
                  </td>
                  <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                    Download
                  </td>
                </tr>
              </thead>

              <tbody>
                {campaignRepotData.map((campaign, index) => (
                  <tr
                    className="border-b text-[16px] font-karla text-gray-900  whitespace-nowrap"
                    key={0 + index}
                  >
                    <td className="pl-[6.0rem] pr-[2.1rem]  py-4">
                      0{index + 1}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                      {campaign.CustomerName}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left">
                      {campaign.AgentNames}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem]  py-4  text-left	">
                      {campaign.CallDuration}
                    </td>
                    <td className="pl-[1.0rem] pr-[2.1rem] py-4 text-left	">
                      {campaign.CallStatus}
                    </td>
                    <td
                      className="pl-[1.0rem] pr-[2.1rem]  py-4 text-left"
                      onClick={() => Feedback(campaign.EventId)}
                    >
                      <svg
                        width="21"
                        height="15"
                        viewBox="0 0 21 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.9235 7.125C17.9035 2.72813 14.1035 0 10.0035 0C5.90348 0 2.10348 2.72813 0.0834848 7.125C0.0284215 7.24328 0 7.37095 0 7.5C0 7.62905 0.0284215 7.75672 0.0834848 7.875C2.10348 12.2719 5.90348 15 10.0035 15C14.1035 15 17.9035 12.2719 19.9235 7.875C19.9785 7.75672 20.007 7.62905 20.007 7.5C20.007 7.37095 19.9785 7.24328 19.9235 7.125ZM10.0035 13.125C6.83348 13.125 3.83348 10.9781 2.10348 7.5C3.83348 4.02187 6.83348 1.875 10.0035 1.875C13.1735 1.875 16.1735 4.02187 17.9035 7.5C16.1735 10.9781 13.1735 13.125 10.0035 13.125ZM10.0035 3.75C9.21236 3.75 8.439 3.96993 7.7812 4.38199C7.12341 4.79404 6.61072 5.37971 6.30797 6.06494C6.00522 6.75016 5.926 7.50416 6.08034 8.23159C6.23468 8.95902 6.61565 9.6272 7.17506 10.1517C7.73447 10.6761 8.4472 11.0333 9.22312 11.1779C9.99905 11.3226 10.8033 11.2484 11.5342 10.9645C12.2651 10.6807 12.8898 10.2001 13.3294 9.58339C13.7689 8.9667 14.0035 8.24168 14.0035 7.5C14.0035 6.50544 13.5821 5.55161 12.8319 4.84835C12.0818 4.14509 11.0644 3.75 10.0035 3.75ZM10.0035 9.375C9.60792 9.375 9.22124 9.26503 8.89234 9.05901C8.56345 8.85298 8.3071 8.56014 8.15573 8.21753C8.00435 7.87492 7.96474 7.49792 8.04191 7.13421C8.11908 6.77049 8.30957 6.4364 8.58927 6.17418C8.86898 5.91195 9.22534 5.73337 9.6133 5.66103C10.0013 5.58868 10.4034 5.62581 10.7689 5.76773C11.1343 5.90964 11.4467 6.14996 11.6664 6.45831C11.8862 6.76665 12.0035 7.12916 12.0035 7.5C12.0035 7.99728 11.7928 8.47419 11.4177 8.82582C11.0426 9.17746 10.5339 9.375 10.0035 9.375Z"
                          fill="#7C8998"
                        />
                      </svg>
                    </td>
                    {/* <td className="pl-[6.1rem] pr-[0.1rem]  py-4 text-left	">
                      <div className="flex items-center">
                        <input
                          id="default-checkbox"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 text-blue-600 bg-gray-100"
                        />
                        <label
                          htmlFor="default-checkbox"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        ></label>
                      </div>
                    </td> */}
                    <td className="pl-[1.1rem] pr-[0.1rem] py-4 text-left	">
                      {campaign.ScheduledTime}
                    </td>
                    <td className="pl-[1.1rem] pr-[0.1rem] py-4 text-left">
                      {campaign.callRecordVF && campaign.CallStatus === "ANSWERED" ? (
                        <audio id="audio1" preload="true" controls>
                          <source
                            src={`${api}uploads/downloadedWaveFile/${
                              campaign.callRecordVF.split(".")[0]
                            }.wav`}
                            type="audio/wav"
                          />
                        </audio>
                      ) : (
                        <p>Your browser does not support HTML5 Audio! 😢</p>
                      )}
                    </td>
                    <td className="pl-[2.1rem] pr-[0.1rem] py-4 text-center	">
                      <svg
                        width="25px"
                        height="25px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={Download}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 3C12.5523 3 13 3.44772 13 4V12.5858L15.2929 10.2929C15.6834 9.90237 16.3166 9.90237 16.7071 10.2929C17.0976 10.6834 17.0976 11.3166 16.7071 11.7071L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16C11.7348 16 11.4804 15.8946 11.2929 15.7071L7.2929 11.7071C6.90238 11.3166 6.90238 10.6834 7.2929 10.2929C7.68342 9.90237 8.31659 9.90237 8.70711 10.2929L11 12.5858V4C11 3.44772 11.4477 3 12 3ZM4.00001 14C4.55229 14 5.00001 14.4477 5.00001 15C5.00001 15.9772 5.00485 16.3198 5.05765 16.5853C5.29437 17.7753 6.22466 18.7056 7.41474 18.9424C7.68018 18.9952 8.02276 19 9.00001 19H15C15.9772 19 16.3198 18.9952 16.5853 18.9424C17.7753 18.7056 18.7056 17.7753 18.9424 16.5853C18.9952 16.3198 19 15.9772 19 15C19 14.4477 19.4477 14 20 14C20.5523 14 21 14.4477 21 15C21 15.0392 21 15.0777 21 15.1157C21.0002 15.9334 21.0004 16.4906 20.9039 16.9755C20.5094 18.9589 18.9589 20.5094 16.9755 20.9039C16.4907 21.0004 15.9334 21.0002 15.1158 21C15.0778 21 15.0392 21 15 21H9.00001C8.96084 21 8.92225 21 8.88423 21C8.06664 21.0002 7.50935 21.0004 7.02456 20.9039C5.0411 20.5094 3.49061 18.9589 3.09608 16.9755C2.99965 16.4906 2.99978 15.9334 2.99999 15.1158C3 15.0777 3.00001 15.0392 3.00001 15C3.00001 14.4477 3.44772 14 4.00001 14Z"
                          fill="#0F1729"
                        />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-8">
          <Pagination />
        </div>
      </div>
    </div>
  );
}
export default AgentCampDetailReport;

const CampaignFeedBack = ({ visible, onClose, data }) => {
  if (!visible) return null;
  console.log("//////////////////", data, "\\\\\\\\\\\\\\");
  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed w-full p-4 overflow-y-auto md:inset-0 h-modal md:h-full z-50"
    >
      <div className="relative w-58 h-full max-w-2xl md:h-auto">
        <div className="relative lg:bottom-[5rem] lg:top-[5rem] lg:left-[32rem] right-0 bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-green-400 h-2.5 rounded-full"
              style={{ width: "100%" }}
            ></div>
          </div>
          <div className="p-6 rounded-md space-y-8">
            <div className="flex">
              <h1 className="font-bold text-lg">Feedback</h1>
              <button className="ml-auto" onClick={onClose}>
                <Cross />
              </button>
            </div>
            {data.map((item) => (
              <>
                <div className="grid grid-cols-3">
                  <div className="col-span-1">
                    <h1 className="text-gray-400 text-md">Agent Name</h1>
                    <p className="text-sm mt-1">{item.AgentNames}</p>
                  </div>
                  <div className="col-span-1">
                    <h1 className="text-gray-400 text-md">Number</h1>
                    <p className="text-sm mt-1">{item.CustomerName}</p>
                  </div>
                  <div className="col-span-1">
                    <h1 className="text-gray-400 text-md">Follow up Date</h1>
                    <p className="text-sm mt-1">{item.ScheduledTime}</p>
                  </div>
                </div>
                <div>
                  <h1 className="text-gray-400 text-md">Question</h1>
                  {Object.entries(item.Feedback.question).map(
                    ([key, value]) => (
                      <div className="text-sm mt-1" key={key}>
                        {key}&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{value}
                      </div>
                    )
                  )}
                </div>
                <div>
                  <h1 className="text-gray-400 text-md">Label</h1>
                  {Object.entries(item.Feedback.labels).map(([key, value]) => (
                    <p className="text-sm mt-1" key={key}>
                      {key} &nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;{value}
                    </p>
                  ))}
                </div>
                <div>
                  <h1 className="text-gray-400 text-md">Comment</h1>
                  <p className="text-sm mt-1">{item.Feedback.comment}</p>
                </div>
              </>
            ))}
            {/* <div>
              <button
                className="font-bold text-lg rounded-full w-36 h-12 mt-12 flex justify-center items-center"
                style={{
                  backgroundImage:
                    "linear-gradient(177.88deg, #F46262 100%, #C21C1C 100%)",
                  color: "white",
                }}
              >
                <Recall className="mr-2" />
                Recall
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
