import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";
import React from "react";
// import sidebar and headers
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Call from "../Call/Call";
import FeedBack from "../CreateCampaign/FeedBack";

import { WebsocketProvider } from "../../context/WebsocketProvider";

const PersistLogin = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [sidebaropen, setSidebarOpen] = useState(false);
  const [currsidemenu, setCurrsidemenu] = useState(() => {
    // Read the value from local storage on initial load
    return localStorage.getItem("currsidemenu") || "Dashboard";
  });
  const [showModal, setShowModal] = useState(true);
  const [check, setcheck] = useState();
  const openSidebar = () => {
    setSidebarOpen(!sidebaropen);
  };
  const [onlineStatus, setOnlineStatus] = useState(
    localStorage.getItem("onlineStatus") || "initial_status"
  );

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        navigate("/login");
      } finally {
        setIsLoading(false);
      }
    };
    // ON refresh we tring to get new acces token using refresh cookie token
    // this is to keep the user not go login in every rfresh
    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
  }, []);



  useEffect(() => {
    // Update localStorage whenever currsidemenu changes
    localStorage.setItem("currsidemenu", currsidemenu);
  }, [currsidemenu]);

  return (
    <>
      {isLoading ? (
        <p>IS LOADING...............</p>
      ) : (
        <div className="flex h-screen antialiased text-gray-900 bg-primarybg">
          <Sidebar
            sidebaropen={sidebaropen}
            setCurrsidemenu={setCurrsidemenu}
            currsidemenu={currsidemenu}
          />
          <div className="flex-1 h-full overflow-x-hidden overflow-y-auto no-scrollbar p-4">
            <Header
              openSidebar={openSidebar}
              currsidemenu={currsidemenu}
              onlineStatus={onlineStatus}
              setOnlineStatus={setOnlineStatus}
            />
            <WebsocketProvider>
              <main className="mt-4">
                <Outlet />
                {auth?.roles?.includes("contact-agent") ? (
                  <Call onlineStatus={onlineStatus} />
                ) : (
                  <></>
                )}
              </main>
            </WebsocketProvider>
          </div>
        </div>
      )}
    </>
  );
};

export default PersistLogin;
