import React, { useEffect, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";

import { NewCampaign, AgentCustomerList, CreateAgent } from "./pages/index";
import AddUser from "./pages/Admin/AddUser";

import { AGENT_ACTIVITY } from "./utils/ApiUrls";

import { Layout, RequireAuth, PersistLogin } from "./components";
import {
  Navigate,
  useNavigate,
  useLocation,
  Route,
  Routes,
} from "react-router-dom";
import { ROLES } from "./utils/Data";
import useAuth from "./hooks/useAuth";
import CallFeedback from "./components/Feedbacks/Feedback";
import Adminroutes, { CommonRoutes, AgentRoutes } from "./routes";
import useFetch from "./hooks/useFetch";

const API_ENDPOINT = process.env.REACT_APP_WS_URL;

function App() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchData = useFetch();

  useEffect(() => {
    if (
      location.pathname == "/" &&
      auth &&
      auth?.roles &&
      localStorage.getItem("IsLoggedIn")
    ) {
      auth?.roles?.includes("contact-admin")
        ? navigate("/admin/dashboard", { replace: true })
        : navigate("/agent/dashboard", { replace: true });
    }

    //--------------------------- Send request when browser tab -----------------------//
    const handleTabClose = async (event) => {
      event.preventDefault();

      // const res_data = await fetchData(
      //   "post",
      //   { status: "unavailable", usertype: "agent", windowClose : "TabClosed" },
      //   AGENT_ACTIVITY
      // );

      console.log("beforeunload event triggered");
    };

    window.addEventListener("beforeunload", handleTabClose);
  }, [auth]);

  const GetRoutes = () => {
    return <div>App</div>;
  };

  return (
    <Routes>
      {/* Public Route */}
      {CommonRoutes.map((Menu, index) => (
        <Route key="index" path={Menu.path} element={Menu.component} />
      ))}

      <Route path="/" element={<Layout auth={auth} />}>
        {/* Agent Paths */}
        <Route path="/" element={<PersistLogin />}>
          <Route
            path="agent"
            element={<RequireAuth allowedRoles={[ROLES.Agent]} />}
          >
            {AgentRoutes.map((Menu, index) => (
              <Route key={index} path={Menu.path} element={Menu.component} />
            ))}
          </Route>
          <Route
            key="customer-list"
            path="customer-list/"
            element={<AgentCustomerList />}
          ></Route>
          {/* Admin Routes */}
          <Route
            path="admin"
            element={<RequireAuth allowedRoles={[ROLES.Admin]} />}
          >
            {Adminroutes.map((Menu, index) => (
              <Route key={index} path={Menu.path} element={Menu.component} />
            ))}
            <Route
              key="new_campaign"
              path="campaign/create/:id"
              element={<NewCampaign />}
            />
            <Route
              key="customer_list"
              path="customer/list/"
              element={<NewCampaign />}
            />
            <Route
              key="new_agent"
              path="agent/create/:id"
              element={<AddUser />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
