import React from 'react';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto';

// ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({call_data}) => {
    const data = {
        labels: ['Answerd', 'Not Answerd'],
        datasets: [
          {
            data:call_data,
            borderWidth: 0,
            backgroundColor: [
                "#6BC698",
                "#E85434"

              ],
                      },
        ],
      }


      const options = {
        maintainAspectRatio:false,
        responsive: true, 
        // cutout: 60,
        plugins: {
            title: {
                
            },
            tooltip:{
              backgroundColor:"white",
              titleColor:"black",
              bodyColor:"black",
              borderColor:"black",
              borderWidth:"2"
            },


            legend: {
              display: true,
              position: 'bottom',
              labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                color:'#9AA7B5',
                font: {
                  size: 15
                },
                padding:30,
              }
            },
        }
    
    }


  return (
    <Doughnut data={data} options={options} />
  )
}

export default DonutChart