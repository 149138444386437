import { AGENT_STATUS_COLOR_CODE } from "../../utils/Data";

function AgentReportPopUp({ visible, onClose, loginDetails }) {
  if (!visible) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="bg-white h-30 p-4 pt-2 rounded-md">
        <div className="flex justify-between p-4">
          <h1 className="text-lg font-bold">Login Activity</h1>
          <button onClick={onClose}>
            <svg
              width="23"
              height="21"
              viewBox="0 0 23 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2797 6.61039C15.1829 6.51198 15.0678 6.43386 14.9409 6.38056C14.814 6.32725 14.678 6.2998 14.5405 6.2998C14.4031 6.2998 14.267 6.32725 14.1401 6.38056C14.0133 6.43386 13.8981 6.51198 13.8013 6.61039L11.4171 9.02539L9.03295 6.61039C8.93588 6.51249 8.82063 6.43483 8.6938 6.38185C8.56697 6.32887 8.43103 6.3016 8.29375 6.3016C8.15647 6.3016 8.02053 6.32887 7.89369 6.38185C7.76686 6.43483 7.65162 6.51249 7.55455 6.61039C7.45747 6.70829 7.38047 6.82452 7.32793 6.95243C7.2754 7.08034 7.24836 7.21744 7.24836 7.35589C7.24836 7.49434 7.2754 7.63144 7.32793 7.75935C7.38047 7.88727 7.45747 8.00349 7.55455 8.10139L9.94914 10.5059L7.55455 12.9104C7.45696 13.008 7.37951 13.1241 7.32665 13.2521C7.2738 13.38 7.24658 13.5173 7.24658 13.6559C7.24658 13.7945 7.2738 13.9317 7.32665 14.0597C7.37951 14.1876 7.45696 14.3038 7.55455 14.4014C7.65133 14.4998 7.76648 14.5779 7.89335 14.6312C8.02023 14.6845 8.15631 14.712 8.29375 14.712C8.43119 14.712 8.56727 14.6845 8.69414 14.6312C8.82101 14.5779 8.93616 14.4998 9.03295 14.4014L11.4171 11.9864L13.8013 14.4014C13.8981 14.4998 14.0133 14.5779 14.1401 14.6312C14.267 14.6845 14.4031 14.712 14.5405 14.712C14.678 14.712 14.814 14.6845 14.9409 14.6312C15.0678 14.5779 15.1829 14.4998 15.2797 14.4014C15.3773 14.3038 15.4548 14.1876 15.5076 14.0597C15.5605 13.9317 15.5877 13.7945 15.5877 13.6559C15.5877 13.5173 15.5605 13.38 15.5076 13.2521C15.4548 13.1241 15.3773 13.008 15.2797 12.9104L12.8851 10.5059L15.2797 8.10139C15.3773 8.00378 15.4548 7.88765 15.5076 7.7597C15.5605 7.63175 15.5877 7.49451 15.5877 7.35589C15.5877 7.21728 15.5605 7.08004 15.5076 6.95209C15.4548 6.82413 15.3773 6.708 15.2797 6.61039Z"
                fill="#7B8897"
              />
              <path
                d="M11.4113 0C9.35214 0 7.33922 0.615814 5.62709 1.76957C3.91496 2.92332 2.58052 4.5632 1.79252 6.48182C1.00451 8.40044 0.798333 10.5116 1.20005 12.5484C1.60178 14.5852 2.59336 16.4562 4.0494 17.9246C5.50545 19.3931 7.36056 20.3931 9.38015 20.7982C11.3997 21.2034 13.4931 20.9955 15.3955 20.2007C17.2979 19.406 18.924 18.0602 20.068 16.3335C21.212 14.6068 21.8226 12.5767 21.8226 10.5C21.8226 9.12112 21.5533 7.75574 21.0301 6.48182C20.5069 5.2079 19.74 4.05039 18.7732 3.07538C17.8064 2.10036 16.6587 1.32694 15.3955 0.799265C14.1324 0.271591 12.7785 0 11.4113 0ZM11.4113 18.9C9.76397 18.9 8.15363 18.4073 6.78393 17.4843C5.41423 16.5613 4.34668 15.2494 3.71627 13.7145C3.08587 12.1796 2.92093 10.4907 3.2423 8.86124C3.56368 7.2318 4.35694 5.73506 5.52178 4.5603C6.68662 3.38554 8.17071 2.58552 9.78638 2.2614C11.4021 1.93729 13.0767 2.10364 14.5987 2.73941C16.1206 3.37519 17.4214 4.45184 18.3366 5.83321C19.2518 7.21458 19.7403 8.83863 19.7403 10.5C19.7403 12.7278 18.8628 14.8644 17.3008 16.4397C15.7388 18.015 13.6203 18.9 11.4113 18.9Z"
                fill="#7B8897"
              />
            </svg>
          </button>
        </div>
        <div className="bg-white rounded-md p-6 h-[25rem] w-[25rem] overflow-y-auto">
          <div className="grid grid-cols-3 mt-8">
            <div className="col-span-1">
              {loginDetails.map((d, index) => (
                <div key={index + 1}>
                  <div className="flex space-x-20">
                    <div>
                      <h1 className="font-md">
                        {new Date(d.created_at).toLocaleDateString()}
                      </h1>
                      <p className="text-gray-400 font-sm">
                        {new Date(d.created_at).toLocaleTimeString()}
                      </p>
                    </div>
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="8"
                          cy="8"
                          r="7.25"
                          fill="white"
                          stroke="#AFAFAF"
                          strokeWidth="1.5"
                        />
                      </svg>

                      <svg
                        width="2"
                        viewBox="0 0 2 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-[7px]"
                      >
                        <rect width="1.5" fill="#E6E6E6" className="h-[5rem]" />
                      </svg>
                    </div>

                    <div
                      className={`mt-[-3px] text-center  text-${
                        AGENT_STATUS_COLOR_CODE[d.activity]
                      }-600`}
                    >
                      <span
                        className={`inline-flex items-center gap-1 rounded-full bg-${
                          AGENT_STATUS_COLOR_CODE[d.activity]
                        }-50 px-2 py-1 text-xs font-semibold text-${
                          AGENT_STATUS_COLOR_CODE[d.activity]
                        }-600`}
                      >
                        <span
                          className={`h-1.5 w-1.5 rounded-full bg-${
                            AGENT_STATUS_COLOR_CODE[d.activity]
                          }-600`}
                        ></span>
                        {d.activity}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentReportPopUp;
