import Swal from "sweetalert2";


export const  TopDisappearingMessage= (position, timer, icon, message, stop=true) => {
  const Tost = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      if (stop){
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    }
  })
  
  //Do something with the input
  return ( 
    Tost.fire({
    icon: icon,
    title: message
  })
  )
};



