import React from 'react'
import { Link } from "react-router-dom";
import { ReactComponent as Start } from "../../assets/images/svg/Start.svg";
import { ReactComponent as Edit } from "../../assets/images/svg/Edit.svg";
import { ReactComponent as Delete } from "../../assets/images/svg/Delete.svg";
import CampaignList from '../Agent/CampaignList';
import { CamptheadData } from '../../utils/Data';

const Campaign = () => {

  return (
    <div className="bg-white p-4">
          <CampaignList  theadData={CamptheadData} user_type="admin" type="campaignlist_admin" camp_search={true} />
    </div>
  )
}

export default Campaign