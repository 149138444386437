import React from "react";
import { createContext, useEffect,useRef, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import useAuth from "../hooks/useAuth";

const WebsocketContext = createContext({});


export const WebsocketProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [val, setVal] = useState(null);
  const {auth} = useAuth();
  const ws = useRef(null);

  useEffect(() => {
    let user_id =""

    if(auth?.user_details?.type == "agent"){
      user_id = auth?.user_details?.useragent
    }
    else{
      user_id = auth?.user_details?.user
    }
    
    function connect() {
      console.log("========sock url---------",process.env.REACT_APP_WS_URL)
        const socket = new W3CWebSocket(process.env.REACT_APP_WS_URL+'ws/campaign/'+user_id+'/');
        socket.addEventListener('open', function (event) {
          console.log('ws connected');
          setIsReady(true);
        });

        socket.addEventListener('close', function (event) {
          setIsReady(false);
          console.log('ws is disconnected');

          setTimeout(function() {
            connect();
          }, 1000);
          
        });

        socket.addEventListener('message', function (message) {
          setVal(JSON.parse(message.data))
        })

        socket.onerror = e => {
          console.log('error', e)
          }
        
        ws.current = socket;

        return () => {
          socket.close();
        };
    }
    connect();

  }, []);

  const ret = [isReady, val, ws.current?.send.bind(ws.current)];

  return (
    <WebsocketContext.Provider value={ret}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketContext;
